import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

interface RadioGroupProps {
    defaultValue?: string;
  options: string[];
  onChange: (value: string) => void;
}

const RadioGroup: React.FC<RadioGroupProps> = ({ options, defaultValue, onChange }) => {
  const [selectedValue, setSelectedValue] = useState<string>(''); 

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSelectedValue(newValue);
    onChange(newValue); 
  };

  return (
    <Form.Group controlId="radioGroup">
      <div className="d-flex flex-column">
        {options.map((option, index) => (
          <div key={index} className="d-flex align-items-center mb-2">
            <Form.Check.Input
              key={index}
              type="radio"
              name="customRadioGroup"
              value={option}
              checked={selectedValue === option}
              onChange={handleRadioChange}
              className="d-flex align-items-center radio-label-spacing"
            />
            <Form.Check.Label className="ms-2">{option}</Form.Check.Label>
            </div>
        ))}
      </div>
    </Form.Group>
  );
};

export default RadioGroup;

export default function authHeader() {
  // Retrieve the user information
  const userStr = localStorage.getItem("user");
  const accessToken = localStorage.getItem("access_token")
  
  let user = null;
  if (userStr) {
    user = JSON.parse(userStr);
  }

  // Initialize organization variable
  let organization = '';

  // Check if user exists and has a non-empty org_ids
  if (user && Array.isArray(user.org_ids) && user.org_ids.length > 0) {
    organization = user.org_ids[0]; // Use the first org in org_ids
  }

  // Prepare headers based on whether there's a valid user and token
  const headers = {
    Authorization: '', // Initialize with empty string for the case where there's no token
    Organization: organization, // Add the organization or an empty string if it's not found
  };

  if (accessToken) {
    headers.Authorization = 'Bearer ' + JSON.parse(accessToken); // for Spring Boot back-end
    // headers['x-access-token'] = user.access_token;       // for Node.js Express back-end
  }

  return headers;
}

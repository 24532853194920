import React, { useState, useEffect } from 'react';

interface CopyButtonProps {
  textToCopy: string;
  messageType: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ textToCopy, messageType }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [copied]);

  return (
    <i onClick={copyToClipboard} className={`${copied ? 'bi-check-lg' : 'bi-copy'} copy-button-${messageType}`} id='copy-button'></i>
  );
};

export default CopyButton;

import React, { useState, ChangeEvent, FormEvent } from "react";
import { Modal, Button } from "react-bootstrap";
import UserService from "../services/user.service";
import IUser from "../types/user.type";
import { useAuth } from "../context/UserState"; // Import the useAuth hook
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook

export interface ResetPasswordModalProps {
  show: boolean;
  handleClose: () => void;
  currentUser: IUser | null;
  showCloseButton?: boolean; // Optional parameter
  isForced?: boolean; // Optional parameter
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  show,
  handleClose,
  currentUser,
  showCloseButton = true, // Default value
  isForced = false, // Default value
}) => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [retypeNewPassword, setRetypeNewPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { logOut } = useAuth(); // Destructure the logOut function
  const navigate = useNavigate(); // Initialize the navigate hook

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };

  const handleRetypePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRetypeNewPassword(event.target.value);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (newPassword === retypeNewPassword && currentUser) {
      try {
        await UserService.updatePassword(currentUser, newPassword);
        setNewPassword("");
        setRetypeNewPassword("");
        setErrorMessage("");
        handleClose();
        logOut(); // Log out the user after successful password change
        navigate("/login"); // Redirect to login page
      } catch (error) {
        setErrorMessage("Failed to update password.");
      }
    } else {
      setErrorMessage("Passwords do not match");
    }
  };

  return (
    <Modal show={show} onHide={showCloseButton ? handleClose : undefined}>
      <Modal.Header closeButton={showCloseButton}>
        <Modal.Title>
          {isForced ? (
            <>
              Your Password has Expired!
              <br />
              Please Create a New Password
            </>
          ) : (
            "Reset Password"
          )}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit} className="password-reset-form">
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
          <div className="form-group">
            <label>New Password</label>
            <input
              type="password"
              value={newPassword}
              onChange={handlePasswordChange}
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <label>Retype New Password</label>
            <input
              type="password"
              value={retypeNewPassword}
              onChange={handleRetypePasswordChange}
              className="form-control"
              required
            />
          </div>
          <Button type="submit" className="btn btn-primary">
            Confirm New Password
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ResetPasswordModal;

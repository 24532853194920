import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/UserState"; // Adjust the path as necessary
import AuthService from "../services/auth.service"; // Adjust the path as necessary
import ResetPasswordModal from "./ResetPasswordModal.component";
import IUser from "../types/user.type"; // Adjust the path as necessary

interface PrivateRouteProps {
  component: React.ComponentType<any>;
  rolesPermitted?: string[]; // Array of required permission strings
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  rolesPermitted,
}) => {
  const location = useLocation();
  const { setRedirectURL } = useAuth();
  const {currentUser } = useAuth() as { currentUser: IUser | null }; 
  const isAuthenticated = AuthService.isAuthenticated();
  const hasPermission = AuthService.isPermitted(rolesPermitted);
  const isPasswordExpired = AuthService.isPasswordExpired();
  const [showModal, setShowModal] = useState(isPasswordExpired);
  useEffect(() => {
    if (!isAuthenticated || !hasPermission) {
      const redirectPath = location.pathname;
      setRedirectURL(redirectPath);
    }
    
  }, [isAuthenticated, hasPermission, location.pathname, setRedirectURL, isPasswordExpired]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (isAuthenticated && hasPermission) {
    if (isPasswordExpired && showModal) {
      return (
        <ResetPasswordModal
          show={showModal}
          handleClose={handleCloseModal}
          currentUser={currentUser}
          showCloseButton={false} 
          isForced={true} 
        />
      );
    } else {
      return <Component />;
    }
  } else {
    return <Navigate to="/login"/>;
  }
};

export default PrivateRoute;
import React, { createContext, useContext, useState, ReactNode, useMemo } from 'react';


type ChatContextType = {
   
    handleSessionID: (chat: any) => void,
    newChat: () => void,
    toReset: boolean,
    setToReset: (toReset: boolean) => void,
    isHistoryVisible : boolean,
    toggleChatHistory: () => void,
    sessionID: string, // Add sessionID to the type
    setSessionID: (id: string) => void // Add setSessionID to the type
};

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) throw new Error('useChat must be used within an ChatProvider');
  return context;
};

type ContextProviderProps = {
  children: ReactNode;
};

export const ChatProvider: React.FC<ContextProviderProps> = ({ children }) => {
     
    const [toReset, setToReset] = useState<boolean>(false)
    const [isHistoryVisible, setHistoryVisible] = useState(true);
    const [sessionID, setSessionID] = useState<string>(''); // Initialize sessionID state

    function handleSessionID(session_id: string) {
        setSessionID(session_id)
         
    }
    
    function newChat() {
      setToReset(true)
    }

    const toggleChatHistory = () => {
      setHistoryVisible(prev => !prev);
    };

    const value = useMemo(() => ({
    handleSessionID,
    newChat,
    toReset,
    setToReset,
    isHistoryVisible,
    toggleChatHistory
,
    sessionID,
    setSessionID
  }), [toReset, isHistoryVisible, sessionID]);

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};





import axios from "axios";
import authHeader from "./auth-header";
import { API_BASE_URL } from "../constants";
import ApiCollection from "../types/collection.type";
class CollectionService {
    private baseUrl = API_BASE_URL + 'collection';
    async getCollection(collection_id: string): Promise<ApiCollection> { //use an id from org instead of whole org obj
      const response = await axios.get(`${this.baseUrl}/${collection_id}`,{ headers: authHeader() });
      return response.data;
    }
    async getCollections(): Promise<ApiCollection[]> {
      const response = await axios.get(this.baseUrl, { headers: authHeader() });
      return response.data;
    }
    async createCollection(collection: ApiCollection): Promise<ApiCollection> {
      const response = await axios.post(this.baseUrl, collection, { headers: authHeader() });
      return response.data;
    }
  
    async updateCollection(collection: ApiCollection): Promise<ApiCollection> {
      const response = await axios.put(`${this.baseUrl}/${collection.id}`, collection, { headers: authHeader() });
      return response.data;
    }
    async deleteCollection(collection: ApiCollection): Promise<ApiCollection[]> {
      try {
        const response = await axios.delete(`${this.baseUrl}/${collection.id}`, { headers: authHeader() });
        return response.data;
      } catch (error) {
        console.error("Error fetching collection:", error);
        throw error; 
      }
    }
}
export default new CollectionService();

import axios from "axios";
import authHeader from "./auth-header";
import { API_BASE_URL } from "../constants";
import ApiProject from "../types/project.type";
class ProjectService {
    private baseUrl = API_BASE_URL + 'project';
    async getProject(project_id: string): Promise<ApiProject> { //use an id from org instead of whole org obj
      const response = await axios.get(`${this.baseUrl}/${project_id}`,{ headers: authHeader() });
      return response.data;
    }
    async getProjects(): Promise<ApiProject[]> {
      const response = await axios.get(this.baseUrl, { headers: authHeader() });
      return response.data;
    }
    async createProject(project: ApiProject): Promise<ApiProject> {
      const response = await axios.post(this.baseUrl, project, { headers: authHeader() });
      return response.data;
    }
  
    async updateProject(project: ApiProject): Promise<ApiProject> {
      const response = await axios.put(`${this.baseUrl}/${project.id}`, project, { headers: authHeader() });
      return response.data;
    }
    async deleteProject(project: ApiProject): Promise<ApiProject[]> {
      try {
        const response = await axios.delete(`${this.baseUrl}/${project.id}`, { headers: authHeader() });
        return response.data;
      } catch (error) {
        console.error("Error fetching collection:", error);
        throw error; 
      }
    }
}
export default new ProjectService();

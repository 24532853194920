import { useNavigate } from 'react-router-dom';
import { useState, useEffect, createContext } from "react"; 
import React, { Fragment } from 'react';
import './projecttemplate.css'; 
import { useToast } from "../../context/ToastProvider";
import ProjectModal from "./projectmodal.component";
import ProjectService from '../../services/project.service';
import ProjectFileService from '../../services/projectfile.service';
import ApiProject from '../../types/project.type'; 
import ApiProjectFile from '../../types/projectfile.type'; 
import { Table, Accordion, Button, Modal, Spinner, Collapse, Dropdown, ButtonGroup } from "react-bootstrap";

interface ProjectsProps {
  selectedTab: string;
}

const Projects: React.FC<ProjectsProps> = ({ selectedTab }) => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<ApiProject[]>([]);
  const [projectfiles, setProjectFiles] = useState<ApiProjectFile[]>([]);   
  
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [projectToRename, setProjectToRename] = useState<ApiProject | null>(null);
  const { addToast } = useToast();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [projectfileToDelete, setProjectFileToDelete] = useState<ApiProjectFile | null>(null);
  const [projectToDelete, setProjectToDelete] = useState<ApiProject | null>(null);
  const [showDeleteButton, setShowDeleteButton] = useState<{ [projectId: string]: boolean }>({});


  const [openProjectFileRows, setOpenProjectFileRows] = useState<{ [projectId: string]: { [fileIndex: number]: boolean } }>({});
  const [openProjectAccordion, setOpenProjectAccordion] = useState<{ [projectId: string]: boolean }>({});  // To track open/close state of each accordion
  const [sortOrder, setSortOrder] = useState("Descending");

  useEffect(() => {
    if (projects.length > 0 && projectfiles.length > 0) {
      const deleteButtonState: { [projectId: string]: boolean } = {};
      projects.forEach((project) => {
        const filteredFiles = projectfiles.filter((file) => file.project_id === project.id);
        deleteButtonState[project.id] = filteredFiles.length === 0;
      });
      setShowDeleteButton(deleteButtonState);
    }
  }, [projects, projectfiles]); 

  const toggleRow = (projectId: string, index: number) => {
    setOpenProjectFileRows(prevState => ({
      ...prevState,
      [projectId]: {
        ...prevState[projectId],
        [index]: !prevState[projectId]?.[index] // Toggle the state for the specific project and file
      }
    }));
  };

  const toggleAccordion = (projectId: string) => {
    setOpenProjectAccordion(prevState => ({
      ...prevState,
      [projectId]: !prevState[projectId]  // Toggle the open/closed state of the accordion for the specific project
    }));
  };

  const setProjectSortOrder = (sort: string, projects: ApiProject[]) => {
    if (sort === "Descending") {
      const sortedProjects = [...projects].sort((a, b) => {
        if (!a.id || !b.id) return 0;
        return b.id.toString().localeCompare(a.id.toString());
      });
      setProjects(sortedProjects);
    } else if (sort === "Ascending") {
      const sortedProjects = [...projects].sort((a, b) => {
        if (!a.id || !b.id) return 0;
        return a.id.toString().localeCompare(b.id.toString());
      });
      setProjects(sortedProjects);
    }
  }

  const handleSortSelect = (eventKey: any) => {
    if (eventKey === "0") {
      const order = "Descending";
      setSortOrder(order);
      setProjectSortOrder(order, projects);
    } else if (eventKey === "1") {
      const order = "Ascending";
      setSortOrder(order);
      setProjectSortOrder(order, projects);
    }
  };

  useEffect(() => { 
    const fetchData = async () => {
      try {
        await Promise.all([fetchProjects()]);
        await fetchProjectFiles(); 
        
        // Initialize all project accordions as collapsed
        setOpenProjectAccordion(projects.reduce((acc: { [key: string]: boolean }, project) => {
          acc[project.id] = false; // set all project accordions to false (collapsed)
          return acc;
        }, {}));
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, [selectedTab]);
  
  useEffect(() => {
    if (projects.length > 0) {
       setOpenProjectAccordion(
         projects.reduce((acc: { [key: string]: boolean }, project) => {
           acc[project.id] = false; // Initialize all project accordions to false (collapsed)
           return acc;
         }, {})
       );
    }
 }, [projects]);

  const fetchProjects = async () => { 
    try { 
      const projects = await ProjectService.getProjects(); 
      setProjects(projects);
      setProjectSortOrder(sortOrder, projects); 

    } catch (error) { 
      console.log(error); 
    } 
  }; 

  const fetchProjectFiles = async () => {
    try {
      const projectfiles = await ProjectFileService.getProjectFiles();  
      setProjectFiles(projectfiles);
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleCreateClick = () => {
      setProjectToRename(null);
      setShowProjectModal(true);
  };

  const handleAddProject = (project: ApiProject) => {
    fetchProjects();
  };

  const handleEditModalClose = () => {
    setProjectToRename(null);
    setShowProjectModal(false);
  };

  const handleDownloadClick = async (projectfile: ApiProjectFile) => {
    const projectfile_id = projectfile.id;
    const projectfile_filename = projectfile.filename
    try {
      const url = await ProjectFileService.getProjectFileDownloadURL(projectfile_id)
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", ""); 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      addToast("success", "Project File Downloaded", projectfile_filename + " has been downloaded to your computer successfully.");
    } catch (error) {
      console.log(error);
      addToast("error", "Download Error", "An error occurred while attempting to download " + projectfile_filename + ". Please try again.");
    }
  };

  const handleRunProjectTemplate = (project_id: string) => {
    navigate(`/createprojectfile`, { state: { project_id } });
  };

  const handleDeleteConfirm = async () => {
      try {
        setIsDeleting(true);
        if (projectfileToDelete) {
            await ProjectFileService.deleteProjectFile(projectfileToDelete);
            setProjectFiles((prevProjectFiles: ApiProjectFile[]) => 
                prevProjectFiles.filter((projectfile: ApiProjectFile) => projectfile.id !== projectfileToDelete.id)
            );
            addToast("success", "Project File Deleted", `The project file "${projectfileToDelete.filename}" was deleted successfully.`);
        } else 
          if (projectToDelete) {
            await ProjectService.deleteProject(projectToDelete);
            setProjects((prevProject: ApiProject[]) => 
              prevProject.filter((project: ApiProject) => project.id !== projectToDelete?.id)
            );
            addToast("success", "Project Deleted", `The project "${projectToDelete?.project_name}" was deleted successfully.`);
        }
      } catch (error) {
        console.log(error);
        if (projectfileToDelete) {
          addToast("error", "Delete Error", "An error occurred while deleting the project file. Please try again.");
        }
        else if (projectToDelete) {
          addToast("error", "Delete Error", "An error occurred while deleting the project. Please try again.");
        }
      } finally {
        setShowDeleteModal(false);
        setIsDeleting(false);
        setProjectFileToDelete(null);
        setProjectToDelete(null);
      }
  };

  const getStatusBadgeClass = (status: string) => {
    switch (status) {
      case "Uploaded":
        return { bgColor: "#aec6e8", textColor: "#0d428f" };
      case "Processing":
        return { bgColor: "#ffbe45", textColor: "#8f5d00" };
      case "Active":
        return { bgColor: "#a4ebaf", textColor: "#206b2c" };
      case "Rejected":
        return { bgColor: "#edada1", textColor: "#c72b0e" };
      case "Deleting":
        return { bgColor: "#e34c30", textColor: "#f7f1f0" };
      default:
        return { bgColor: "#d1dcf8", textColor: "#5173c9" };
    }
  };

  const handleDeleteClick = (projectfile: ApiProjectFile) => {
    setProjectFileToDelete(projectfile);
    setShowDeleteModal(true);
  };

  const handleProjectDeleteClick = (project: ApiProject) => {
    setProjectToDelete(project);
    setShowDeleteModal(true);
  }

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setProjectFileToDelete(null);
    setProjectToDelete(null);
  };
  
  return ( 
    <div className="projecttemplate-container"> 
       
        <div className="projecttemplate-content"> 
          
          <div className="projecttemplate-body"> 
            <div className="d-flex justify-content-end">
            <div className="d-flex justify-content-end align-items-center">
              <span><i>Sort:</i>&nbsp;&nbsp;</span>
              <Dropdown as={ButtonGroup} onSelect={handleSortSelect}>
                <Dropdown.Toggle variant="outline-secondary" size="sm">
                  {sortOrder} ▼
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="0">Descending (Newest to Oldest)</Dropdown.Item>
                  <Dropdown.Item eventKey="1">Ascending (Oldest to Newest)</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
              <Button variant="outline-primary" 
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>{e.stopPropagation(); handleCreateClick();}} 
                  className="add-project-button"
                  title="Create a new Project.">
                <i className="bi bi-folder-plus fs-5 ms-0"></i> Project
              </Button>
              <div className="create-project-modal">
                        <ProjectModal
                            projectToEdit={projectToRename}
                            onSaveSuccess={(project) => {
                              handleAddProject(project);
                          }}
                          onClose={handleEditModalClose}
                          onShow={showProjectModal}
                        />
                      </div>
            </div>
            <br></br>
          

            <Modal show={showDeleteModal} onHide={handleDeleteCancel}>
              <Modal.Header closeButton>
                <Modal.Title>Delete Project{projectfileToDelete ? " File" : "" }?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {projectfileToDelete ? (
                  <>
                    Please confirm you want to delete this Project File:<br></br><br></br> <strong><i>{projectfileToDelete?.filename}</i></strong>
                  </>
                ) : (
                  <>
                    Please confirm you want to delete this project:<br></br><br></br> <strong><i>{projectToDelete?.project_name}</i></strong>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleDeleteCancel}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleDeleteConfirm} disabled={isDeleting} style={{ width: "80px" }}>
                  {isDeleting ? <Spinner animation="border" size="sm" /> : "Confirm"}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>



          <Accordion alwaysOpen>
            {projects.map((project, p_index) => {
              const filteredFiles = projectfiles.filter(file => file.project_id === project.id);
              return (
                <React.Fragment key={project.id}>
                      <Accordion.Item eventKey={p_index.toString()}>
                      <Accordion.Header onClick={() => toggleAccordion(project.id)}>
                      <div className="accordion-header-content">
                        <span><h6>{project.project_name}</h6></span>
                              <div className="add-project-button">
                                {showDeleteButton[project.id] && (
                                  <i
                                  className="bi bi-trash fs-6"
                                  onClick={() => handleProjectDeleteClick(project)}
                                ></i> 
                                )}
                                &nbsp;&nbsp;&nbsp;
                                <Button variant="outline-primary" 
                                    onClick={() => handleRunProjectTemplate(project.id)}  
                                    className="create-project-button ps-0" title="Create a new Project File.">
                                      <i className="bi bi-file-earmark-plus"></i> Project File
                                </Button>
                              </div>
                      </div>
                      </Accordion.Header>
                    <Accordion.Body>
                    <Table hover>
                    <thead>
                      <tr>
                        <th>Template Name</th>
                        <th>Collection</th>
                        <th>File Name</th>
                        <th>Status</th>
                        <th className="text-end">Actions&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredFiles.length > 0 ? (
                        filteredFiles.map((projectfile, index) => (
                          <Fragment key={projectfile.id}>
                            <tr>
                              <td>
                                <Button
                                  variant="link"
                                  onClick={() => toggleRow(project.id, index)} // Pass project ID and file index
                                  aria-controls={`collapse-row-${project.id}-${index}`} // Unique ID based on project and index
                                  aria-expanded={!!openProjectFileRows[project.id]?.[index]} // Check if this row is open
                                >
                                  {!!openProjectFileRows[project.id]?.[index] ? <i className="bi bi-dash-lg"></i> : <i className="bi bi-plus-lg"></i>}
                                </Button>
                                {projectfile.template_name}
                              </td>
                              <td>{projectfile.collection_name}</td>
                              <td>{projectfile.created_date
                        ? new Date(projectfile.created_date).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })
                        : 'N/A'}</td>
                              <td>
                                <span
                                  style={{
                                    backgroundColor: `${getStatusBadgeClass(projectfile.status).bgColor}`,
                                    color: `${getStatusBadgeClass(projectfile.status).textColor}`,
                                    display: "inline-block",
                                    textAlign: "center",
                                    fontWeight: "600",
                                    padding: "4px 6px",
                                    borderRadius: "8px",
                                    width: "90px",
                                  }}
                                >
                                  {projectfile.status}
                                </span>
                              </td>
                              <td className="text-end">
                                {projectfile.status === "Active" && (
                                  <i className="bi bi-trash fs-6" title="Delete Project File." onClick={() => handleDeleteClick(projectfile)}></i>
                                )}
                                {projectfile.status === "Active" && (
                                  <i className="bi bi-download fs-6" title="Download Project File." onClick={() => handleDownloadClick(projectfile)}></i>
                                )}
                              </td>
                            </tr>
                            
                                <Collapse in={!!openProjectFileRows[project.id]?.[index]}>
                                <tr>
                                  <td colSpan={5} style={{ paddingLeft: '20%' }}>
                                  <div id={`collapse-row-${project.id}-${index}`} className="bg-light" >
                                    <div className="row">
                                      <div className="col-md-2">
                                        <i>File Name:</i>
                                      </div>
                                      <div className="col-md-9">
                                        {projectfile.filename}
                                      </div>
                                    </div>
                                  </div>
                                  </td>
                                </tr>
                                </Collapse>
                              
                          </Fragment>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={5}>No Project Files available</td>
                        </tr>
                      )}
                    </tbody>

                  </Table>
                  </Accordion.Body>
                  </Accordion.Item>
                  <br></br>
                  
                </React.Fragment>
              );
            })}
            </Accordion>

        </div> 
      
      
    </div> 
  ); 
}; 
 
export default Projects;
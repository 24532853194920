import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import OrganizationAdmin from './organization.component'; // Adjust path as necessary
import UserAdmin from './user.component'; // Adjust path as necessary
import FeedbackAdmin from './feedback.component'; // Adjust path as necessary
import styles from "./admin.module.css"

const Admin: React.FC = () => {
    const [key, setKey] = useState('home');
    return (
        <div className={styles["admin-panel-container"]}>
        <h2 className={styles["admin-panel-title"]}>Admin Panel</h2>
        <Tabs defaultActiveKey="orgs">
          <Tab eventKey="orgs" title="Organizations">
            <OrganizationAdmin />
          </Tab>
          <Tab eventKey="users" title="Users">
            <UserAdmin />
          </Tab>
          <Tab eventKey="feedback" title="Feedback">
            <FeedbackAdmin />
          </Tab>
        </Tabs>
      </div>
        
    );
}

export default Admin;

import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import IUser from "../../types/user.type";
import UserService from "../../services/user.service";
import { ApiOrganization } from "../../types/organization.type";
import OrganizationService from "../../services/organization.service";
import Select from "react-select";

interface OrganizationModalProps {
  userToEdit: ApiOrganization | null;
  onSaveSuccess: (user: IUser) => void;
  onClose: () => void;
  onShow: boolean;
}

const OrganizationModal: React.FC<OrganizationModalProps> = ({ userToEdit, onSaveSuccess, onClose, onShow }) => {

  const [users, setUsers] = useState<IUser[]>([]);
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [organizations, setOrganizations] = useState<ApiOrganization[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const permissionOptions = [
    { value: "admin", label: "Admin" },
    { value: "org-admin", label: "Org Admin" },
    { value: "filecollections.view", label: "File Collections View" },
    { value: "projecttemplates", label: "Projects & Templates"},
  ];

  useEffect(() => {
    fetchOrganizations();

    if (userToEdit) {
      startEditing(userToEdit)
    } else {
      const newUser: IUser = {
        id: '',
        force_password_change: true,
        
      }; 
      userToEdit = newUser;
      startEditing(userToEdit)
    }    
  }, [userToEdit]);

  // Event handler to stop event propagation
  const handleStopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const fetchOrganizations = async () => {
    const orgs = await OrganizationService.getOrganizations();
    setOrganizations(orgs);
  };

  const createUser = async (user: IUser) => {
    const response = await UserService.createUser(user);
    const newUser = { ...user, id: response.id };
    setUsers([...users, newUser]);
    setCurrentUser(null);
  };

  const updateUser = async (user: IUser) => {
    const updatedUser = await UserService.updateUser(user);
    setUsers(users.map((o) => (o.id === updatedUser.id ? updatedUser : o)));
    setIsEditing(false);
    setCurrentUser(null);
  };

  const createOrUpdateUser = async () => {
    const userData = {
      ...currentUser!,
    };

    if (currentUser?.id) {
      try {
        updateUser(userData);
        onSaveSuccess(userData);
      } catch (error) {
        console.error('Error adding organization:', error);
      } finally {
        onClose();
      }
    } else {
      
      try {
        createUser(userData);
        onSaveSuccess(userData);
      } catch (error) {
        console.error('Error updating organization:', error);
      } finally {
        onClose();
      }
    }

    setIsEditing(false);
    setCurrentUser(null);
  };

  const startEditing = (user: IUser) => {
    setCurrentUser(user);
    setIsEditing(true);

    setCurrentUser({
      ...user,
      id: user.id || '',
      email: user.email || '',
      password: user.password || '',
      permissions: user.permissions || [],
      org_ids: user.org_ids || [],
      org_name: user.org_name || '',
      fname: user.fname || '',
      lname: user.lname || '',
      last_login: user.last_login || new Date(),
      type: user.type || '',
      password_changed_date: user.password_changed_date || new Date(),
      force_password_change: user.force_password_change
    });
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setCurrentUser(null);
  };

  const handleFieldChange = (field: keyof IUser, value: any) => {
    if (currentUser) {
      setCurrentUser((prev) => ({ ...prev, [field]: value }));
    }
  };

  return (
    <>
      <Modal show={onShow} onHide={onClose} size="xl" onClick={handleStopPropagation}>
        <Modal.Header>
          <Modal.Title><h3>{userToEdit && userToEdit.id == '' ? 
                            'Duplicate User':
                            userToEdit ? 
                            'Edit User - ' + (currentUser?.fname || '') + ' ' + (currentUser?.lname || '') : 
                            'New User'}</h3></Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={(e) => {e.stopPropagation(); cancelEditing(); onClose();}}></button>
        </Modal.Header>
        <Modal.Body>
        {isEditing && currentUser && (
          <div className="mt-4">
            <div className="mb-3">
              <input
                type="text"
                autoComplete="false"
                className="form-control mt-2"
                placeholder="First Name"
                value={currentUser.fname || ""}
                onChange={(e) => handleFieldChange("fname", e.target.value)}
              />
              <input
                type="text"
                autoComplete="false"
                className="form-control mt-2"
                placeholder="Last Name"
                value={currentUser.lname || ""}
                onChange={(e) => handleFieldChange("lname", e.target.value)}
              />
              <input
                type="text"
                autoComplete="false"
                className="form-control mt-2"
                placeholder="Email"
                value={currentUser.email}
                onChange={(e) => handleFieldChange("email", e.target.value)}
              />
              <input
                type="password"
                autoComplete="new-password"
                className="form-control mt-2"
                placeholder="Password"
                value={currentUser.password || ""}
                onChange={(e) => handleFieldChange("password", e.target.value)}
              />
              <Select
                isMulti
                options={[
                  { value: "admin", label: "Admin" },
                  { value: "org-admin", label: "Org Admin" },
                  { value: "filecollections.view", label: "File Collections View" }, // Add this option
                  { value: "projecttemplates", label: "Projects & Templates"},
                ]}
                className="mt-2"
                value={permissionOptions.filter(option =>
                  currentUser?.permissions?.includes(option.value)
                )}
                onChange={(selectedOptions) => {
                  const newOptions = selectedOptions.length > 0 ? selectedOptions.map(option => option.value) : ['']
                  setCurrentUser({ ...currentUser!, permissions: newOptions })
                }}
                placeholder="Select Permissions"
              />
              <select
                className="form-control mt-2"
                value={currentUser.org_ids && currentUser.org_ids.length > 0 ? currentUser.org_ids[0] : ""}
                onChange={(e) => handleFieldChange("org_ids", [e.target.value])}
              >
                <option value="">Select Organization</option>
                {organizations.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                ))}
              </select>

              {/* Toggle switch for force_password_change */}
              <div className="form-check form-switch mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="forcePasswordChange"
                  checked={currentUser.force_password_change || false}
                  onChange={(e) => handleFieldChange("force_password_change", e.target.checked)}
                  style={{
                    backgroundColor: currentUser.force_password_change ? "red" : "",
                    borderColor: currentUser.force_password_change ? "red" : "",
                  }}
                />
                <label className="form-check-label" htmlFor="forcePasswordChange">
                  Force Password Change
                </label>
              </div>
            </div>
          </div>
        )}
        {!isEditing && (
          <div className="mt-4">
            <h2>Create User</h2>
            <div className="">
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                value={currentUser?.fname || ""}
                onChange={(e) => setCurrentUser({ ...currentUser!, fname: e.target.value, force_password_change: currentUser?.force_password_change ?? true })}
                autoComplete="false"
              />
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Last Name"
                value={currentUser?.lname || ""}
                onChange={(e) => setCurrentUser({ ...currentUser!, lname: e.target.value, force_password_change: currentUser?.force_password_change ?? true })}
                autoComplete="false"
              />
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Email"
                value={currentUser?.email || ""}
                onChange={(e) => setCurrentUser({ ...currentUser!, email: e.target.value, force_password_change: currentUser?.force_password_change ?? true })}
                autoComplete="false"
              />
              <input
                type="password"
                className="form-control mt-2"
                placeholder="Password"
                value={currentUser?.password || ""}
                onChange={(e) => setCurrentUser({ ...currentUser!, password: e.target.value, force_password_change: currentUser?.force_password_change ?? true })}
                autoComplete="new-password"
              />
              <Select
                isMulti
                options={[
                  { value: "admin", label: "Admin" },
                  { value: "org-admin", label: "Org Admin" },
                  { value: "user", label: "User" },
                  { value: "filecollections.view", label: "File Collections View" },
                  { value: "projecttemplates", label: "Project & Templates" },
                ]}
                className="mt-2"
                value={permissionOptions.filter(option =>
                  currentUser?.permissions?.includes(option.value)
                )}
                onChange={(selectedOptions) => {
                  const newOptions = selectedOptions.length > 0 ? selectedOptions.map(option => option.value) : ['']
                  setCurrentUser({ ...currentUser!, permissions: newOptions })
                }}
                placeholder="Select Permissions"
              />
              <select
                className="form-control mt-2"
                value={currentUser?.org_ids ? currentUser.org_ids[0] : ""}
                onChange={(e) => setCurrentUser({ ...currentUser!, org_ids: [e.target.value], force_password_change: currentUser?.force_password_change ?? true })}
              >
                <option value="">Select Organization</option>
                {organizations.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                ))}
              </select>
              <div className="form-check form-switch mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="forcePasswordChange"
                  checked={currentUser?.force_password_change !== undefined ? currentUser.force_password_change : true}
                  onChange={(e) => setCurrentUser({ ...currentUser!, force_password_change: e.target.checked })}
                  style={{
                    backgroundColor: (currentUser?.force_password_change !== undefined ? currentUser.force_password_change : true) ? "red" : "",
                    borderColor: (currentUser?.force_password_change !== undefined ? currentUser.force_password_change : true) ? "red" : "",
                  }}
                />
                <label className="form-check-label" htmlFor="forcePasswordChange">
                  Force Password Change
                </label>
              </div>
            </div>
          </div>
        )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e) => {e.stopPropagation(); cancelEditing(); onClose();}}>
            Cancel
          </Button>
          <Button variant="primary" onClick={(e) => {e.stopPropagation(); createOrUpdateUser();}}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrganizationModal;

// setupAxiosInterceptors.ts

import axios from 'axios';
import EventBus from "../common/EventBus";

export const setupAxiosInterceptors = () => {
  axios.interceptors.response.use(response => response, error => {
    if (error.response && error.response.status === 401) {
      // Dispatch a logout event which will be handled in the App component
      EventBus.dispatch("logout");
    }
    return Promise.reject(error);
  });
};

import { useEffect, useRef } from 'react';

const useActivityTracker = (onTimeout: () => void, timeoutDuration: number) => {
  const lastActivityRef = useRef(Date.now());

  useEffect(() => {
    const resetTimer = () => {
      const now = Date.now();
      lastActivityRef.current = now;
      localStorage.setItem('lastActivity', now.toString());
    };

    const updateLastActivityFromStorage = (event: StorageEvent) => {
      if (event.key === 'lastActivity' && event.newValue) {
        lastActivityRef.current = parseInt(event.newValue, 10);
      }
    };

    const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart'];

    events.forEach((event) => window.addEventListener(event, resetTimer));
    window.addEventListener('storage', updateLastActivityFromStorage);

    const checkInactivity = setInterval(() => {
      const now = Date.now();
      const timeSinceLastActivity = now - lastActivityRef.current;

      if (timeSinceLastActivity > timeoutDuration) {
        onTimeout();
      }
    }, 1000);

    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      window.removeEventListener('storage', updateLastActivityFromStorage);
      clearInterval(checkInactivity);
    };
  }, [onTimeout, timeoutDuration]);

  return lastActivityRef;
};

export default useActivityTracker;

import React from 'react'
import { Link } from 'react-router-dom'
import LionFederalLogo from '../images/Lion_Federal_Logo.jpg';

const Footer = () => {
  return (
    <div className="appFooter">
        <div className="footerSplit">
          <div>Powered by&nbsp;<Link to={'https://www.lionfederal.com'} target="_blank">
            <img src={LionFederalLogo} alt="Lion Federal, LLC" className='lionFederalLogo' />
          </Link></div>
          <div><Link to={'https://www.lionfederal.com/about-us.html'} target="_blank">
            About Us
          </Link></div>
        </div>
    </div>
  )
}

export default Footer
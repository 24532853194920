import React, { createContext, useContext, useState, ReactNode } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

type ToastType = "success" | "error" | "info";

interface ToastMessage {
  id: number;
  type: ToastType;
  title: string;
  message: string;
  show: boolean;
  delay?: number;
  persistent?: boolean;
}

interface ToastContextType {
  addToast: (type: ToastType, title: string, message: string, options?: { delay?: number, persistent?: boolean }) => number;
  removeToast: (id: number) => void;
  clearToasts: () => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

export const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastMessage[]>([]);

  const addToast = (type: ToastType, title: string, message: string, options?: { delay?: number, persistent?: boolean }) => {
    const id = Date.now();
    setToasts((currentToasts) => [
      ...currentToasts,
      {
        id,
        type,
        title,
        message,
        show: true,
        delay: options?.delay ?? 10000,
        persistent: options?.persistent ?? false,
      },
    ]);
    return id;
  };

  const removeToast = (id: number) => {
    setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== id));
  };

  const handleCloseToast = (id: number) => {
    setToasts((currentToasts) => currentToasts.map((toast) => (toast.id === id ? { ...toast, show: false } : toast)));
  };

  const clearToasts = () => {
    setToasts([])
  }

  return (
    <ToastContext.Provider value={{ addToast, removeToast, clearToasts }}>
      {children}
      <ToastContainer position="bottom-end" className="p-3 mb-5">
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            bg={toast.type === "success" ? "success" : toast.type === "error" ? "danger" : "info"}
            onClose={() => handleCloseToast(toast.id)}
            show={toast.show}
            delay={toast.persistent ? undefined : toast.delay} 
            autohide={!toast.persistent} 
          >
            <Toast.Header>
              <strong className="me-auto">{toast.title}</strong>
              <small>Just now</small>
            </Toast.Header>
            <Toast.Body className="text-white">{toast.message}</Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  );
};

import axios from "axios";
import { API_BASE_URL } from "../constants";



class AuthService {

  isPasswordExpired(){
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const userPasswordChangedDate = user.password_changed_date;
    const userForcePasswordChange = user.force_password_change;
    if (userForcePasswordChange) {
      return true; // If no password change date, treat it as expired
    }
    if (userPasswordChangedDate) {
      const daysSinceLastChange = (Date.now() - new Date(userPasswordChangedDate).getTime()) / (1000 * 60 * 60 * 24);
      return daysSinceLastChange > 90; // Password is expired if more than 90 days old
    }
  }
  isAuthenticated() {
    let accessToken = localStorage.getItem("access_token");
    if (!accessToken) return false;
    accessToken = JSON.parse(accessToken);

    const exp = JSON.parse(atob(accessToken!.split(".")[1])).exp;
    return Date.now() < exp * 1000; // Convert exp to milliseconds and compare
  }

  isPermitted(rolesPermitted?: string[]): boolean {
    try {
      if (!rolesPermitted) return true; // If no permissions are specified, return true

      const user = JSON.parse(localStorage.getItem("user") || "{}");
      const userPermissions = user.permissions || [];
      return rolesPermitted.some((permission) => userPermissions.includes(permission));
    } catch (error) {
      console.error("Failed to check permissions:", error);
      return false; // Assume no permission if there's an error reading permissions
    }
  }

  login(email: string, password: string) {
    return axios
      .post(
        API_BASE_URL + "login",
        {},
        {
          auth: {
            username: email,
            password,
          },
        }
      )
      .then((response) => {
        if (response.data.access_token) {
          localStorage.setItem("access_token", JSON.stringify(response.data.access_token));
        }
        return response.data.access_token;
      });
  }

  loginSSO(externalId: string, ssoToken: string) {
    return axios.post(API_BASE_URL + "login/sso", { external_id: externalId, sso_token: ssoToken }).then((response) => {
      if (response.data.token) {
        localStorage.setItem("access_token", JSON.stringify(response.data.token));
      }
      return response.data.token;
    });
  }

  logout() {
    localStorage.removeItem("access_token");
  }

  async getCurrentUser(token?: string) {
    let access_token = token;

    if (!access_token) {
      let localStorageToken = localStorage.getItem("access_token")
      if (!localStorageToken) return Promise.reject(new Error("No access token available"));
      access_token = JSON.parse(localStorageToken)
    }

    return axios
      .get(API_BASE_URL + "user/current", { headers: { Authorization: `Bearer ${access_token}` } })
      .then((response) => {
        const user = { ...response.data };
        return user;

      })
      .catch((error) => {
        console.error("Error fetching current user:", error);
        return Promise.reject(error);
      });
  }

  async refreshToken(): Promise<string> {
    let localStorageToken = localStorage.getItem("access_token");
    if (!localStorageToken) return Promise.reject(new Error("No access token available"));
    
    let access_token = JSON.parse(localStorageToken);
    
    try {
      const response = await axios.post(
        `${API_BASE_URL}login/extend-token`,
        {},
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
  
      const newAccessToken = response.data.access_token;
      localStorage.setItem('access_token', JSON.stringify(newAccessToken));
      return newAccessToken;
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }
  }
}

export default new AuthService();

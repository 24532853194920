import axios from 'axios';
import authHeader from './auth-header';
import { API_BASE_URL } from "../constants";
import IUser from "../types/user.type";

class UserService {
  getPublicContent() {
    return axios.get(API_BASE_URL + '');
  }
  
  private baseUrlAdmin = API_BASE_URL + 'admin/user';
  private baseUrlUser = API_BASE_URL + 'user';
  
  async getUser(user_id: string): Promise<IUser> { //use an id from org instead of whole org obj
    const response = await axios.get(`${this.baseUrlAdmin}/${user_id}`,{ headers: authHeader() });
    return response.data;
  }

  async getUsers(): Promise<IUser[]> {
    try {
      const response = await axios.get(this.baseUrlAdmin, { headers: authHeader() });
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;  // or handle it appropriately
    }
  }
  
  async createUser(user: IUser): Promise<IUser> {
    try {
      const response = await axios.post(this.baseUrlAdmin, user, { headers: authHeader() });
      return response.data;
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;  // or handle it appropriately
    }
  }
  
  async updateUser(user: IUser): Promise<IUser> {
    try {
      const response = await axios.put(`${this.baseUrlAdmin}/${user.id}`, user, { headers: authHeader() });
      return response.data;
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;  // or handle it appropriately
    }
  }

  async updatePassword(user: IUser, newPassword: string): Promise<void> {
    user.password = newPassword
    try {
      const response = await axios.put(`${this.baseUrlUser}/current`,  user, { headers: authHeader() });
      return response.data;
    } catch (error) {
      console.error('Error updating password:', error);
      throw error;
    }
  }
}

export default new UserService();

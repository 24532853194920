import React, { createContext, useContext, useState, ReactNode, useMemo, useCallback } from 'react';
import IUser from '../types/user.type'; // Adjust the import path as necessary for your project structure
import { ApiOrganization } from '../types/organization.type';
import OrganizationService from '../services/organization.service';
import AuthService from '../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useCollections } from './CollectionState';
import useActivityTracker from '../common/useActivityTracker';

const INACTIVITY_TIMEOUT = 30 * 60 * 1000;
const REFRESH_BEFORE_EXPIRY = 5 * 60 * 1000;

type AuthContextType = {
  currentUser: IUser | undefined;
  setCurrentUser: (user: IUser | undefined) => void;
  redirectURL: string | undefined; // Define the type for the redirect URL
  setRedirectURL: (url: string | undefined) => void; // Function to update the redirect URL
  organization: ApiOrganization | undefined; // Added to manage the organization state
  setOrganization: (organization: ApiOrganization | undefined) => void; // Function to update the organization
  loginAction: (email: string, password: string) => void;
  logOut: () => void;
  ssoLoginAction: (externalId: string, token: string) => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within an AuthProvider');
  return context;
};

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);
  const [redirectURL, setRedirectURL] = useState<string | undefined>(undefined); // State for managing the redirect URL
  const [organization, setOrganization] = useState<ApiOrganization | undefined>(undefined); // State for managing the organization
  const logoutTimeoutRef = React.useRef<number | null>(null);
  const { resetSelectedCollection } = useCollections()
  const navigate = useNavigate();

  React.useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser));
    }

    const storedOrganization = localStorage.getItem('organization');
    if (storedOrganization) {
      setOrganization(JSON.parse(storedOrganization));
    }

    setupLogoutTimeout()
  }, []);
  
  async function loginAction (email: string, password: string) {
    try {
      const accessToken = await AuthService.login(email, password);
      await loginCallback(accessToken);
      
    } catch (error) {
      throw error;
    }
  }
  async function ssoLoginAction (externalId: string, token: string) {
    try {
      const accessToken = await AuthService.loginSSO(externalId, token)
      await loginCallback(accessToken)

      navigate('/chat')
    } catch (error) {
      throw error
    }
  }

  async function loginCallback(token: string) {
    try {
      const user = await AuthService.getCurrentUser(token)

      setCurrentUser(user)
      localStorage.setItem('user', JSON.stringify(user));
      const org: ApiOrganization = await OrganizationService.getOrganization(user.org_ids[0]);
      setOrganization(org);
      localStorage.setItem('organization', JSON.stringify(org));
      setupLogoutTimeout();
    } catch (err) {
      throw err
    }
  }

  const logOut = useCallback(() => {
    AuthService.logout();
    setCurrentUser(undefined);
    localStorage.removeItem('user');
    setOrganization(undefined);
    localStorage.removeItem('organization');
    localStorage.removeItem('selectedTools')
    if (logoutTimeoutRef.current) {
      clearTimeout(logoutTimeoutRef.current);
      logoutTimeoutRef.current = null;
    }
    resetSelectedCollection()
  }, []);

  const lastActivityRef = useActivityTracker(() => {
    console.log('User inactive for 30 minutes. Logging out.');
    logOut();
  }, INACTIVITY_TIMEOUT);

  const setupLogoutTimeout = useCallback(() => {
    if (logoutTimeoutRef.current) {
      clearTimeout(logoutTimeoutRef.current);
    }

    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      const decodedToken = jwtDecode<{ exp: number }>(accessToken);
      const expirationTime = decodedToken.exp * 1000;
      const timeLeft = expirationTime - Date.now();

      if (timeLeft > 0) {
        const refreshTime = timeLeft - REFRESH_BEFORE_EXPIRY;
        console.log(`Setting token refresh in ${refreshTime / 1000} seconds.`);

        const timeout = window.setTimeout(async () => {
          const now = Date.now();
          const inactivityDuration = now - lastActivityRef.current;

          console.log(`Inactivity duration: ${inactivityDuration / 1000} seconds.`);

          if (inactivityDuration < INACTIVITY_TIMEOUT) {
            console.log('User is active. Refreshing token.');
            try {
              await AuthService.refreshToken();
              console.log('Token refreshed successfully.');
              setupLogoutTimeout();
            } catch (error) {
              console.error('Failed to refresh token:', error);
              logOut();
            }
          } else {
            console.log('User is inactive. Logging out.');
            logOut();
          }
        }, refreshTime);

        logoutTimeoutRef.current = timeout as unknown as number;
      } else {
        logOut();
      }
    }
  }, [lastActivityRef, logOut]);


  const value = useMemo(() => ({
    currentUser,
    setCurrentUser,
    redirectURL,
    setRedirectURL,
    organization,
    setOrganization,
    loginAction,
    logOut,
    ssoLoginAction
  }), [currentUser, redirectURL, organization]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};





import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AuthService from '../services/auth.service';
import { useAuth } from '../context/UserState';
import styles from './admin/admin.module.css'
import { ApiOrganization } from '../types/organization.type';
import OrganizationService from '../services/organization.service';

interface SSOParams extends Record<string, string> {
    externalId: string;
}
  

// http://localhost:3000/login/sso/de496f18-29ac-4c28-bff3-ca0a3fd72f36?sso_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTUwOTkyMTMuNzE3MzkyNywianRpIjoiNGI5NTc3OTktOGQ3Ny00NzY3LWE5ZTMtMGZiMmQ3YjcyMzE3IiwidWlkIjo4MTQyNTEzLCJzaWQiOjI4MCwidW5pcXVlX2lkIjoiMjgwLTgxNDI1MTMiLCJub3RlIjoidW5pcXVlIGlkIC0gc3NvIHVzZSBvbmx5IGFuZCBpcyBhIGNvbXBvc2l0ZSBpZDogc2Nob29saWQgLSB1c2VyaWQiLCJleHAiOjE3MTUwOTk1ODMuNzE3MzkyNywibmJmIjoxNzE1MDk5MjEzLjcxNzM5MjcsImlzcyI6Imh0dHBzOi8vZmxpbnRoaWxsLm15c2Nob29sYXBwLmNvbSIsImhpZCI6IiIsIm9uYXBpIjoiaHR0cHM6Ly9mbGludGhpbGwubXlzY2hvb2xhcHAuY29tL2FwaS91c2VyLzgxNDI1MTMiLCJwb3N0X3VybCI6Imh0dHBzOi8vZmxpbnRoaWxsLm15c2Nob29sYXBwLmNvbS9hcGkvc3NvL2F1dGgvYXNraHVza3lodWIiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0In0.43G4IZqlLD3HZcUb16xZwkChWVBZEqv0AgU9j_On-Qg
const LoginSSO: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  
  const { setCurrentUser, setOrganization, ssoLoginAction } = useAuth();
  const params = useParams<SSOParams>();
  const [searchParams] = useSearchParams();
  const ssoToken = searchParams.get('sso_token');

  useEffect(() => {
    if (!params.externalId || !ssoToken) {
      setMessage('Invalid access. externalId or SSO token is missing.');
      return;
    }

    handleSSOLogin(params.externalId, ssoToken);
  }, [params.externalId, ssoToken]);

  const handleSSOLogin = async (externalId: string, token: string) => {
    try {
      setLoading(true);
      await ssoLoginAction(externalId, token)
    } catch(error) {
      const resMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      setLoading(false);
      setMessage(resMessage);
    }
  };

  return (
    <div className="col-md-12">
      <div className={`${styles.card} card-container`}>
        {loading ? (
          <div className="text-center">
            <span className="spinner-border spinner-border-lg align-center"></span>
          </div>
        ) : (
          <div>
            {message && (
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginSSO;

import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import ApiFile from "../../../types/file.type";
import ApiCollection from "../../../types/collection.type";
import FileService from "../../../services/file.service";
import { useToast } from "../../../context/ToastProvider"; // Ensure you are using the Toast context

interface AddFilesModalProps {
  show: boolean;
  onHide: () => void;
  collection: ApiCollection | null;
  selectedFiles: string[]
  onFilesAdded: (files: ApiFile[]) => void;
  availableFiles: ApiFile[]; // Pass in the list of all files to select from
}

const AddFilesModal: React.FC<AddFilesModalProps> = ({
  show,
  onHide,
  collection,
  selectedFiles,
  onFilesAdded,
  availableFiles,
}) => {

  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>(selectedFiles)
  const { addToast } = useToast();

  useEffect(() => {
    setSelected(selectedFiles); // Reset selected files when modal opens
  }, [selectedFiles]);

  const handleCheckboxChange = (fileId: string) => {
    setSelected((prevSelected) =>
      prevSelected.includes(fileId)
        ? prevSelected.filter((id) => id !== fileId)
        : [...prevSelected, fileId]
    );
  };

  const handleAddFiles = async () => {
    if (collection && selected.length >= 0) {
      try {
        setIsAdding(true);

        await Promise.all(
          availableFiles.map(async (file) => {
            const isSelected = selected.includes(file.id);
            const isInCollection = file.collections.includes(collection.id);

            let updatedCollections;

            if (isSelected && !isInCollection) {
              updatedCollections = [...file.collections, collection.id];
            } else if (!isSelected && isInCollection) {
              updatedCollections = file.collections.filter((c) => c !== collection.id);
            } else {
              return;
            }
            await FileService.updateFileCollection(file.id, updatedCollections);
          })
        );

        const updatedFiles = availableFiles.map((file) => {
          const isSelected = selected.includes(file.id);
          const isInCollection = file.collections.includes(collection.id);

          let updatedCollections;

          if (isSelected && !isInCollection) {
            updatedCollections = [...file.collections, collection.id];
          } else if (!isSelected && isInCollection) {
            updatedCollections = file.collections.filter((c) => c !== collection.id);
          } else {
            updatedCollections = file.collections;
          }

          return { ...file, collections: updatedCollections };
        });

        onFilesAdded(updatedFiles);
        addToast("success", "Files Updated", "Files were successfully updated in the collection.");
      } catch (error) {
        console.error("Error updating files in collection:", error);
        addToast("error", "Update Failed", "An error occurred while updating files in the collection. Please try again.");
      } finally {
        setIsAdding(false);
        onHide();
      }
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Manage files for {collection?.collection_name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {availableFiles.map((file) => (
            <Form.Check
              key={file.id}
              type="checkbox"
              label={file.filename}
              checked={selected.includes(file.id)}
              onChange={() => handleCheckboxChange(file.id)}
            />
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleAddFiles} disabled={isAdding}>
          {isAdding ? <Spinner animation="border" size="sm" /> : "Update Files"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddFilesModal;

import { useEffect, useState, useRef, FormEvent } from "react";
import { useLocation } from 'react-router-dom';
import { useAuth } from "../../../context/UserState";
import IUser from "../../../types/user.type";
import { useNavigate } from "react-router-dom";
import './templateQuestions.css'
import { useToast } from "../../../context/ToastProvider";
import ProgressBar from 'react-bootstrap/ProgressBar';

import ApiTemplateInstructionsObj from '../../../types/templateinstructionsobject.type'; 
import ApiTemplateInstructions from '../../../types/templateinstructions.type'; 
import RadioGroup from './radiogroup';
import { Form, InputGroup, FormControl } from 'react-bootstrap';


import ProjectFileService from '../../../services/projectfile.service';
import ApiTemplate from '../../../types/template.type'; 
import { Button, Alert } from "react-bootstrap";

const TemplateQuestions: React.FC = () => {
  const { currentUser } = useAuth() as { currentUser: IUser | null };
  const navigate = useNavigate();
  const location = useLocation();
  const percentageInputRef = useRef(null); 
  const numberInputRef = useRef(null); 

  const { templateInstructionsObj } = location.state as { templateInstructionsObj: ApiTemplateInstructionsObj } || {};
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { addToast } = useToast();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [templateInstructions, setTemplateInstructions] = useState<ApiTemplateInstructions[]>(templateInstructionsObj?.instructions || []);
  const [showNextButton, setShowNextButton] = useState<boolean>(true);
  const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false);
  

  const [progressStatus, setProgressStatus] = useState<number>(0);
  const [percentageValue, setPercentageValue] = useState<number>();
  const [numberValue, setNumberValue] = useState<number>();
  const [textValue, setTextValue] = useState<string>('');
  const [radioValue, setRadioValue] = useState<string>('');
  const [radioOptions, setRadioOptions] = useState<string[]>([]);

  const [showPercentage, setShowPercentage] = useState<boolean>(false);
  const [showNumber, setShowNumber] = useState<boolean>(false); 
  const [showText, setShowText] = useState<boolean>(false);
  const [showRadioGroup, setShowRadioGroup] = useState<boolean>(false); 
  

  
  
  useEffect(() => {
    if (templateInstructions.length == 0) {
      handleCancel();
    }
    if (templateInstructions[questionIndex]?.question_type === 'Percentage') {
      setShowNumber(false);
      setShowRadioGroup(false);
      setShowText(false);

      setPercentageValue(parseInt(templateInstructions[questionIndex]?.user_response_answer) || undefined);
      setNumberValue(undefined);
      setTextValue('')
      setRadioValue('');
      setRadioOptions([]);  // Set an empty array for no options

      setShowPercentage(true);
    } else if (templateInstructions[questionIndex]?.question_type === 'Number') {
      setShowPercentage(false);
      setShowRadioGroup(false);
      setShowText(false);

      setPercentageValue(-1);
      setNumberValue(parseInt(templateInstructions[questionIndex]?.user_response_answer) || undefined);
      setTextValue('')
      setRadioValue('');
      setRadioOptions([]);  
      
      setShowNumber(true);
    } else if (templateInstructions[questionIndex]?.question_type === 'Text') {
      setShowNumber(false);
      setShowPercentage(false);
      setShowRadioGroup(false);
      
      setPercentageValue(-1);
      setNumberValue(undefined);
      setTextValue(templateInstructions[questionIndex]?.user_response_answer || '');
      setRadioValue('');
      setRadioOptions([]);  
      
      setShowText(true);
    } else {
      setShowPercentage(false);
      setShowNumber(false);
      setShowText(false);
      
      setPercentageValue(undefined);
      setNumberValue(undefined);
      setTextValue('')
      setRadioValue(templateInstructions[questionIndex]?.user_response_answer || '');
  
      const questionType = templateInstructions[questionIndex]?.question_type;
      if (typeof questionType === 'string' && questionType.includes(',')) {
        const options = questionType.split(',').map(option => option.trim());  
        setRadioOptions(options);  
      }
      setShowRadioGroup(true);
    }
  }, [questionIndex, templateInstructions]); 

  const clearFields = () => {
    setShowPercentage(false);
    setShowNumber(false);
    setShowText(false);
    setShowRadioGroup(false);     

    setPercentageValue(-1);
    setNumberValue(-1);
    setTextValue('')
    setRadioValue('');
    setRadioOptions([]);  // Set an empty array for no options
    setErrorMessage(null);

      
  }

  const handleCancel = () => {
    navigate(`/projectstemplates`);
  };

  const handleNext = () => { 
    const questions = templateInstructions.length;
    const current_answer = templateInstructions[questionIndex]?.user_response_answer;
    if (current_answer == '' || current_answer == undefined) {
      setErrorMessage('Please select a response to the question.');
    } else if (questions > questionIndex+1) {
      clearFields();
      const prog_num = Math.round(((questionIndex+1)/questions)*100);
      if (questions === questionIndex + 2) {
        setShowNextButton(false);
        setShowSubmitButton(true);
      }

      setProgressStatus(prog_num)
      setQuestionIndex(questionIndex+1);
    }
  };

  const handlePrevious = () => { 
    const questions = templateInstructions.length;
    if (questionIndex > 0) {
      clearFields();
      if (showSubmitButton) {
        setShowNextButton(true);
        setShowSubmitButton(false);
      }

      const prog_num = Math.round(((questionIndex-1)/questions)*100);
      setProgressStatus(prog_num)
      setQuestionIndex(questionIndex-1)
    }
  };

  const handlePercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPercentageValue(value === '' ? undefined : parseInt(value));
    if (value != '') {
      templateInstructions[questionIndex].user_response_answer = value.toString();
      setTemplateInstructions(templateInstructions);
      }
  };
  
  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNumberValue(value === '' ? undefined : parseInt(value));
    if (value != '') {
    templateInstructions[questionIndex].user_response_answer = value.toString();
    setTemplateInstructions(templateInstructions);
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTextValue(value);
    if (value != '') {
    templateInstructions[questionIndex].user_response_answer = value.toString();
    setTemplateInstructions(templateInstructions);
    }
  };

  const handleRadioChange = (value: string) => {
    templateInstructions[questionIndex].user_response_answer = value
    setTemplateInstructions(templateInstructions);
  };

  const handleEnter = (event: FormEvent) => {
    event.preventDefault();  // Prevent the default form submission behavior
    handleNext();             // Call the handleNext function to move to the next question
  };

  const handleSubmitForm = async () => { 
    const questions = templateInstructions.length;
    const current_answer = templateInstructions[questionIndex]?.user_response_answer;
    if (current_answer == '' || current_answer == undefined) {
      setErrorMessage('Please select a response to the question.');
    } else {
      templateInstructionsObj.instructions = templateInstructions;
      const return_str = await ProjectFileService.processTemplateInstructions(templateInstructionsObj);
      navigate(`/projectstemplates`);
    }
  };

  return (
    <div className="templateQuestions-container">
      {currentUser && (
        <div className="templateQuestions-content">
          <div className="templateQuestions-header">
            <h3>{templateInstructionsObj?.template_name} ({templateInstructionsObj?.project_name})</h3>
          </div>
          <div className="templateQuestions-body">
            {errorMessage && (
                  <Alert variant="danger">
                      {errorMessage.split('\n').map((msg, index) => (
                          <div key={index}>{msg}</div> // Display each error message in a separate line
                      ))}
                  </Alert>
                )} 
            <ProgressBar now={progressStatus} label={`${progressStatus}%`} />
            <br></br>
            <div className="mb-4"> 
              <h5><i><strong>{templateInstructions[questionIndex]?.section_number}. {templateInstructions[questionIndex]?.section_header}</strong></i></h5>
            </div>
            <div className="mb-4"> 
              <h6><i><strong>{templateInstructions[questionIndex]?.sub_section_number}. {templateInstructions[questionIndex]?.question}</strong></i></h6>
            </div>
            <br></br>
            <div>
              <Form onSubmit={handleEnter}>
              {showPercentage && (
                <Form.Group controlId="percentageInput">
                  <InputGroup>
                    <FormControl
                      ref={percentageInputRef}
                      type="number"
                      value={percentageValue}
                      onChange={handlePercentageChange}
                      placeholder="Enter Percentage"
                      min="0"
                      max="100"
                      aria-label="Percentage"
                      autoFocus
                    />
                    <InputGroup.Text className="input-group-text">%</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              )}
              {showNumber && (
                <Form.Group controlId="numberInput">
                  <InputGroup>
                    <FormControl
                      ref={numberInputRef}
                      type="number"
                      value={numberValue}
                      onChange={handleNumberChange}
                      placeholder="Enter Number"
                      min="0"
                      aria-label="Number"
                      autoFocus
                    />
                  </InputGroup>
                </Form.Group>
              )}
              {showRadioGroup && (
                <RadioGroup 
                  options={radioOptions} 
                  defaultValue={radioValue}
                  onChange={handleRadioChange} 
                />
              )} 
              {showText && (
                <Form.Group controlId="textInput">
                      <FormControl
                        as="textarea"
                        rows={4}
                        value={textValue}
                        onChange={handleTextChange}
                        aria-label="Response"
                      />
                    </Form.Group>
              )}
              </Form>
            </div>
            <br></br>
            <br></br>
            <div className="mb-2 d-flex justify-content-between"> 
              <div>
                <Button variant="outline-primary" className="d-flex align-items-center ps-0 fs-6" onClick={handleCancel}> 
                  <i className="bi bi-x-circle fs-5 me-1"></i> Cancel
                </Button>
              </div>
              <div className="d-flex">
                <Button variant="outline-primary" className="d-flex align-items-center ps-0 fs-6" onClick={handlePrevious}> 
                  <i className="bi bi-arrow-left-circle-fill fs-5 me-1"></i> Previous
                </Button> &nbsp;&nbsp;{showNextButton && (
                <Button variant="outline-primary" className="d-flex align-items-center ps-2 fs-6" onClick={handleNext}> 
                  Next <i className="bi bi-arrow-right-circle-fill fs-5 me-1"></i>
                </Button> )}{showSubmitButton && (
                <Button variant="primary" className="d-flex align-items-center ps-2 fs-6" onClick={handleSubmitForm}> 
                  Submit
                </Button> )}
              </div>
            </div>

              

          </div>
        </div>
      )}
    </div>
  );
};

export default TemplateQuestions;

import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import ApiCollection from "../../types/collection.type";

interface CollectionModalProps {
  collections: ApiCollection[]; // Use ApiCollection array
  selectedCollections: string[]; // Array of selected collection IDs
  show: boolean;
  onHide: () => void;
  onSave: (selected: string[]) => void; // Callback function to handle saving selected collections
}

const CollectionsSelectModal: React.FC<CollectionModalProps> = ({ collections, selectedCollections, show, onHide, onSave }) => {
  const [selected, setSelected] = useState<string[]>(selectedCollections);

  useEffect(() => {
    setSelected(selectedCollections);
  }, [selectedCollections]);

  const handleCheckboxChange = (id: string) => {
    setSelected(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(selectedId => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSave = () => {
    onSave(selected);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Collections</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {collections.map(collection => (
            <Form.Check
              key={collection.id}
              type="checkbox"
              label={collection.collection_name}
              checked={selected.includes(collection.id)}
              onChange={() => handleCheckboxChange(collection.id)}
            />
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CollectionsSelectModal;

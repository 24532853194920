// FeedbackService.js
import axios from 'axios';
import { API_BASE_URL } from '../constants';
import { ApiFeedback } from '../types/feedback.type';
import authHeader from "./auth-header"; 
 
class FeedbackService {
    private baseUrlAdmin = API_BASE_URL + 'admin/feedback';
    private baseUrl = API_BASE_URL + 'feedback';

    async getFeedback(feedback_id: string): Promise<ApiFeedback> {
        const response = await axios.get(`${this.baseUrlAdmin}/${feedback_id}`, { headers: authHeader() });
        return response.data;
    }

    async getFeedbacks(): Promise<ApiFeedback[]> {
        const response = await axios.get(this.baseUrlAdmin, { headers: authHeader() });
        return response.data;
    }

    async createFeedback(feedback: ApiFeedback): Promise<ApiFeedback> {
        const response = await axios.post(this.baseUrl, feedback, { headers: authHeader() });
        return response.data;
    }

    
}   

export default new FeedbackService();

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setupAxiosInterceptors } from './axios/setupAxiosInterceptors'; // Import the function
import { AuthProvider } from './context/UserState';
import { ChatProvider } from './context/ChatState';
import { ToastProvider } from './context/ToastProvider';
import { CollectionsProvider } from './context/CollectionState';
import { FileLibraryPollingProvider } from './context/FileLibraryPollingProvider';

// Set up Axios interceptor by calling the function
setupAxiosInterceptors();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <CollectionsProvider>
      <AuthProvider>
        <ChatProvider>
          <ToastProvider>
            <FileLibraryPollingProvider>
              <App />
            </FileLibraryPollingProvider>
          </ToastProvider>
        </ChatProvider>
      </AuthProvider>
    </CollectionsProvider>
  </BrowserRouter>
);

reportWebVitals();
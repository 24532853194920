import React, { useState, useEffect, useRef, MutableRefObject, ReactEventHandler } from 'react';
import { trackPromise } from 'react-promise-tracker';
import BouncingDots from '../messages/BouncingDots';
import ErrorPopup from '../errorPopup/ErrorPopup';
import MarkdownMessage from '../messages/MarkdownMessage';
import { API_BASE_URL } from '../../constants';
import authHeader from "../../services/auth-header"; 
import { useChat } from '../../context/ChatState';
import { Card, Spinner } from 'react-bootstrap';
import CopyButton from '../copybutton.component';
import ChatService from '../../services/chat.service'
import {Message}  from '../../types/message.type';
import { Navigate, useSearchParams, useNavigate } from 'react-router-dom'; 
import './ChatWindow.css';
import axios from 'axios'; // Import axios
import FlipMove from 'react-flip-move';
import { useCollections } from '../../context/CollectionState';
import { useAuth } from '../../context/UserState';
import { ApiTool, ToolOption } from '../../types/tool.type';
import PlotlyChart from './PlotlyChart';
import Annotation from './Annotation';

import 'bootstrap-icons/font/bootstrap-icons.css';

import useAutosizeTextArea from './useAutosizeTextArea';
import organizationService from '../../services/organization.service';

export interface Session {
  id: string;
  last_used: string;
  org_id: string;
  title: string;
  user_id: string;
}

const STORAGE_KEY = 'selectedTools';

const ChatWindow = () => {
  const [userMessages, setUserMessages] = useState<Message[]>([]);
  const [systemMessages, setSystemMessages] = useState<Message[]>([]);
  const [userInput, setUserInput] = useState<string>("");
  const [systemResponse, setSystemResponse] = useState<string>("");
  const [backendError, setBackendError] = useState<boolean>(false);
  const [backendErrorMsg, setBackendErrorMsg] = useState<Error>(new Error(''));
  const messageListRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const historySidebarRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const chatBoxRef = useRef<HTMLDivElement>(null)
  const [redirect] = useState<string | null>(null);
  const [sessionID, setSessionID] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [chatSessions, setChatSessions] = useState<Session[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null); // Ref to the dropdown
  const { currentUser, organization } = useAuth()
  const { handleSessionID, toReset, setToReset } = useChat()
  const [editingSessionId, setEditingSessionId] = useState<string | null>(null); // Track which session is being edited
  const [tempSessionTitle, setTempSessionTitle] = useState<string>(""); // Temporary title during editing
  const [isToolPopupOpen, setToolPopupOpen] = useState<boolean>(false);
  const [selectedTools, setSelectedTools] = useState<ToolOption[]>([]);
  const [toolOptions, setToolsOptions] = useState<ToolOption[]>([])
  const toolPopupRef = useRef<HTMLDivElement | null>(null); // Ref to the popup
  const { isHistoryVisible, toggleChatHistory} = useChat();
  const { collections, selectedCollection, setSelectedCollection, fetchCollections } = useCollections();
  
  
  useEffect(() => {
    fetchChatSessions();
    fetchCollections();
  }, []);

  useEffect(() => {
    const savedTools = localStorage.getItem(STORAGE_KEY);
    if (savedTools) {
      setSelectedTools(JSON.parse(savedTools));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(selectedTools));
  }, [selectedTools]);

  useEffect(() => {
    organization && organizationService.getOrgTools(organization).then((tools) => {
      const orgToolOptions = tools.map((tool: ApiTool) => {
        return (
          {
            value: tool.tool_name,
            label: tool.description
          }
        )
      })
      setToolsOptions(orgToolOptions)
    }).catch((error) => {
      console.error('Error fetching org tools:', error);
    })
  }, [organization])

  const fetchChatSessions = async () => {
    try {
      const sessions = await ChatService.getSessions();
      console.log(sessions)
      setChatSessions(sessions);
    } catch (error) {
      console.error('Error fetching chat sessions:', error);
    }
  };
  const toggleMenu = (sessionId: string) => {
    if (editingSessionId === sessionId) return; // Prevent toggling the menu if editing
    if (activeMenu === sessionId) {
      setActiveMenu(null); // Close if the same menu is clicked again
    } else {
      setActiveMenu(sessionId); // Open the clicked menu
    }
  };
  const handleDelete = async (sessionId: string) => {
    console.log('Delete clicked for session:', sessionId);
    try {
      // Call the deleteChatSession service method
      await ChatService.deleteChatSession(sessionId);
  
      // Remove the deleted session from the state
      setChatSessions((prevSessions) => prevSessions.filter((session) => session.id !== sessionId));
  
      // Optionally, clear the chat window if the deleted session was active
      if (sessionId === sessionID) {
        clearSessionId();
        reset();
      }
    } catch (error) {
      console.error('Error deleting chat session:', error);
    }
  };

  const handleRename = (sessionId: string, currentTitle: string) => {
    setEditingSessionId(sessionId); // Set the session being edited
    setTempSessionTitle(currentTitle); // Set the current title in the temp state
  };
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempSessionTitle(e.target.value); // Update the temporary title as user types
  };

  const handleSaveTitle = async (sessionId: string) => {
    console.log('Saving new title:', tempSessionTitle);
  
    try {
      // Call the service to rename the chat session
      await ChatService.updateChatSession(sessionId, tempSessionTitle);
  
      // Update the chat session title in the state after a successful rename
      setChatSessions((prevSessions) =>
        prevSessions.map((session) =>
          session.id === sessionId ? { ...session, title: tempSessionTitle } : session
        )
      );
  
      setEditingSessionId(null); // Exit edit mode
      setActiveMenu(null); // Close the menu
    } catch (error) {
      console.error('Error renaming chat session:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingSessionId(null); // Cancel the edit
    setTempSessionTitle(''); // Reset the temporary title
    setActiveMenu(null); // Close the menu
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, sessionId: string) => {
    if (e.key === 'Enter') {
      handleSaveTitle(sessionId); // Save on Enter key
    } else if (e.key === 'Escape') {
      handleCancelEdit(); // Cancel on Escape key
    }
  };
  const handleToolToggle = (tool: ToolOption) => {
    setSelectedTools((prevSelectedTools) => {
      if (prevSelectedTools.some((t) => t.value === tool.value)) {
        return prevSelectedTools.filter((t) => t.value !== tool.value);
      } else {
        return [...prevSelectedTools, tool];
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        toolPopupRef.current && 
        !toolPopupRef.current.contains(event.target as Node)
      ) {
        setToolPopupOpen(false); // Close the popup if clicked outside
      }
    };

    if (isToolPopupOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener when the component unmounts or popup closes
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isToolPopupOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (editingSessionId !== null) {
        const inputElement = document.getElementById(`session-input-${editingSessionId}`);
        if (inputElement && !inputElement.contains(event.target as Node)) {
          handleSaveTitle(editingSessionId);
        }
      }
      if (activeMenu !== null) {
        const menuElement = document.getElementById(`menu-${activeMenu}`);
        const menuIconElement = document.getElementById(`menu-icon-${activeMenu}`);

        if (
          menuElement &&
          !menuElement.contains(event.target as Node) &&
          menuIconElement &&
          !menuIconElement.contains(event.target as Node)
        ) {
          setActiveMenu(null);
        }
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeMenu, editingSessionId]);
  
   
  useEffect(() => {
    handleSessionID(sessionID)

    if (toReset) {
      reset();
      setToReset(false);
    }
    chatBoxRef.current?.scrollIntoView();
  }, [userMessages, systemMessages, toReset, setToReset]);

  useEffect(() => {
    const sessionId = searchParams.get("session_id");
    if (sessionId) {
      setSessionID(sessionId);

      fetchConversation(sessionId);
    } else {
      clearSessionId();
    }
  }, [searchParams]);


  const fetchConversation = async (sessionId: string) => {
    try {
      const messages = await ChatService.getConversation(sessionId);
      console.log(messages);

      const userMsgs = messages.filter((msg: Message) => msg.type === 'human');
      const systemMsgs = messages.filter((msg: Message) => msg.type === 'ai');

      const updatedSystemMsgs = systemMsgs.map((msg) => {
        let newMessage = { ...msg };
      
        // Parse chart data if available
        if (msg.metadata && msg.metadata.chart_data && msg.metadata.chart_data !== "{}") {
          try {
            const parsedChartData = JSON.parse(msg.metadata.chart_data);
            if (!isObjectEmpty(parsedChartData)) {
              newMessage.chart_data = parsedChartData;
            }
          } catch (e) {
            console.error('Error parsing chart data:', e);
          }
        }
      
        if (msg.metadata && msg.metadata.annotation && msg.metadata.annotation !== "{}") {
          try {
            const parsedAnnotation = typeof msg.metadata.annotation === 'string'
              ? JSON.parse(msg.metadata.annotation)
              : msg.metadata.annotation;
            if (!isObjectEmpty(parsedAnnotation)) {
              newMessage.annotation = parsedAnnotation;
            }
          } catch (e) {
            console.error('Error parsing annotation:', e);
          }
        }
      
        return newMessage;
      });

      setUserMessages(userMsgs);
      setSystemMessages(updatedSystemMsgs);

    } catch (error) {
      handleErrorOccured(error);
    }
  };

  const categorizeSessions = (sessions: Session[]) => {
    const setToMidnight = (date: Date) => {
      date.setHours(0, 0, 0, 0);
      return date;
    };
  
    const today = setToMidnight(new Date());
    const lastWeek = setToMidnight(new Date());
    const lastMonth = setToMidnight(new Date());
    
    lastWeek.setDate(today.getDate() - 7);
    lastMonth.setDate(today.getDate() - 30);
  
    const sortedSessions = sessions.sort((a, b) => new Date(b.last_used).getTime() - new Date(a.last_used).getTime());
    
    return {
      today: sortedSessions.filter(session => new Date(session.last_used) >= today),
      lastWeek: sortedSessions.filter(session => new Date(session.last_used) >= lastWeek && new Date(session.last_used) < today),
      lastMonth: sortedSessions.filter(session => new Date(session.last_used) >= lastMonth && new Date(session.last_used) < lastWeek)
    };
  };

  const { today, lastWeek, lastMonth } = categorizeSessions(chatSessions);

  useEffect(() => {
    if (messageListRef == null || messageListRef.current == null) return;
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  }, [userMessages, systemMessages]);

  const handleUserInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserInput(event.target.value);
  };

  const textareaRef = useAutosizeTextArea(userInput);
  const setFocusToTextArea = () => {
    if (textareaRef.current) {
      textareaRef.current?.focus();
    }
  };

  useEffect(() => {
    setFocusToTextArea(); 
  }, [systemMessages]);

  const handleSendMessage = () => {
    if (userInput) {
        const selectedCollectionName = collections.find(col => col.id === selectedCollection)?.collection_name || 'entire-library';

        const newUserMessage: Message = {
            content: userInput,
            type: "human",
            metadata: { collection_name: selectedCollectionName },
        };
        setUserMessages([...userMessages, newUserMessage]);

        const placeholderSystemMessage: Message = {
            content: '',
            type: "ai",
            metadata: { collection_name: selectedCollectionName },
        };
        setSystemMessages([...systemMessages, placeholderSystemMessage]);

        setUserInput("");
        generateSystemResponse(userInput);
    }
};

  function isObjectEmpty(obj: any): boolean {
    return (
      obj &&
      Object.keys(obj).length === 0 &&
      obj.constructor === Object
    );
  }

  const generateSystemResponse = (userInput: string) => {
    const fetchResponse = async (userInput: string) => {
      setIsLoading(true);
      const controller = new AbortController();
      setAbortController(controller);

      let isChartDataReceived = false;
      const userSelectedTools = selectedTools.map((toolOption) => {
        return toolOption.value
      })

      try {
        let url = `${API_BASE_URL}chat`;
        if (sessionID) {
          url += `/${sessionID}`;
        }

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            q: userInput,
            collectionId: selectedCollection,
            selectedTools: userSelectedTools,
          }),
          signal: controller.signal,
        });
        const reader = response.body?.getReader();
        const decoder = new TextDecoder('utf-8');

        let receivedText = '';
        let buffer = '';

        if (reader) {
          let done = false;

          // Helper function to process each line
          const processLine = (line: string) => {
            if (line.trim() === '') return;
            try {
              const parsed = JSON.parse(line);
          
              const hasChartData = parsed.chart_data && !isObjectEmpty(parsed.chart_data);
              const hasAnnotation = parsed.annotation && !isObjectEmpty(parsed.annotation);
          
              if (hasChartData || hasAnnotation) {
                isChartDataReceived = true;
                setSystemMessages((prevMessages) => {
                  const updatedMessages = [...prevMessages];
                  const lastMessage = updatedMessages[updatedMessages.length - 1];
                  const newMessage: Message = {
                    ...lastMessage,
                    content: parsed.content || lastMessage.content,
                    ...(hasChartData ? { chart_data: parsed.chart_data } : {}),
                    ...(hasAnnotation ? { annotation: parsed.annotation } : {}),
                  };
                  updatedMessages[updatedMessages.length - 1] = newMessage;
                  return updatedMessages;
                });
              } else if (parsed.content) {
                receivedText += parsed.content;
                setSystemMessages((prevMessages) => {
                  const updatedMessages = [...prevMessages];
                  updatedMessages[updatedMessages.length - 1].content = receivedText;
                  return updatedMessages;
                });
              }
            } catch (e) {
              console.error('Error parsing JSON line:', e);
            }
          };
          
        

          while (!done) {
            const { value, done: readerDone } = await reader.read();
            if (value) {
              const chunk = decoder.decode(value, { stream: true });
              buffer += chunk;
              let lines = buffer.split('\n');
              buffer = lines.pop() || '';

              for (let line of lines) {
                processLine(line);
              }
            }
            done = readerDone;
          }
          if (buffer.trim() !== '') {
            processLine(buffer);
          }
        }

        const newSessionID = response.headers.get('session_id');
        if (newSessionID && newSessionID !== sessionID) {
          setSessionID(newSessionID);
          setSearchParams({ session_id: newSessionID });
          fetchChatSessions();
        }
      } catch (error) {
        if (controller.signal.aborted) {
          if (!isChartDataReceived) {
            setSystemMessages((prevMessages) => {
              const updatedMessages = [...prevMessages];
              updatedMessages[updatedMessages.length - 1].content =
                'Response stopped by user.';
              return updatedMessages;
            });
          }
        } else {
          handleErrorOccured(error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchResponse(userInput);
  };

  const toggleToolPopup = () => {
    setToolPopupOpen((prev) => !prev);  // Toggle between open and close
  };

  const handleStopResponse = () => {
    if (abortController) {
      abortController.abort(); // Stop the streaming response
      setIsLoading(false); // Update loading state
    }
  };

  const handleErrorOccured = (error: any) => {
    setBackendErrorMsg(error);
    setBackendError(true);
    if (error.response && error.response.status === 403) {
      reset();
    }
  };
  const handleErrorIgnore = () => {
    setBackendError(false);
  };
  const handleErrorReset = () => {
    setBackendError(false);
    reset();
  };

  const clearSessionId = () => {
    setSessionID('');
    setSearchParams();
  };
  useEffect(() => {
    if (!systemResponse) return;

    const selectedCollectionName = collections.find(col => col.id === selectedCollection)?.collection_name || 'entire-library';
    
    const newSystemMessage: Message = {
        content: systemResponse,
        type: "ai",
        metadata: { collection_name: selectedCollectionName },
    };

    setSystemMessages((prevSystemMessages) => {
        if (prevSystemMessages.length < userMessages.length) {
            return [...prevSystemMessages, newSystemMessage];
        } else {
            const newSystemMessages = [...prevSystemMessages];
            newSystemMessages[newSystemMessages.length - 1] = newSystemMessage;
            return newSystemMessages;
        }
    });
}, [systemResponse]);


  function interleaveHistory(user: Message[], system: Message[]): Message[] {
    let interleavedArr: Message[] = [];
    for (let i = 0; i < user.length; i++) {
        interleavedArr.push(user[i]);
        if (system[i]) {
            interleavedArr.push(system[i]);
        }
    }
    return interleavedArr;
}

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelection = (collectionId: string) => {
    setSelectedCollection(collectionId);
    setIsOpen(false);
  };

  const handleClearSelection = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation(); // Prevent dropdown toggle
    setSelectedCollection('entire-library');
  };
  
  function reset() {
    setUserMessages([]);
    setSystemMessages([]);
    setUserInput("");
    setSystemResponse("");
    clearSessionId();
  }
  const adjustHeight = (textarea: HTMLTextAreaElement) => {
    if (textarea) {
      textarea.style.height = "auto";
      let height = textarea.scrollHeight;

      if (height > 200) {
        height = 200; // Limit the height
        textarea.style.overflowY = "auto";
      } else {
        textarea.style.overflowY = "hidden";
      }

      if (height < 65) {
        height = 38;
      }

      textarea.style.height = `${height}px`;
      textarea.scrollTop = textarea.scrollHeight;
      textareaRef.current?.scrollIntoView();
    }
  };

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  const hasCollectionPermissions = currentUser?.permissions?.includes("filecollections.view")
  const isAdmin = currentUser?.permissions?.includes("admin");

  return (
    <>
      <div
        className={`history-toggle ${isHistoryVisible ? 'history-open' : 'history-closed'}`}
        onClick={(e) => {e.stopPropagation(); toggleChatHistory();}}
      >
        <div className="h-c">
          <i id="history-toggle-btn">
            <i className="bi bi-clock-history" style={{ fontSize: '2rem' }}></i>
          </i>
          <div className="history-text">Chat History</div>
        </div>

        {isHistoryVisible && (
          <i
            className="bi bi-x"
            id="history-toggle-x"
            style={{ fontSize: '1.5rem' }}
            onClick={(event) => {
              event.stopPropagation(); // Prevent the event from bubbling up
              toggleChatHistory();
            }}
          ></i>
        )}
        {isHistoryVisible && (
          <div className="hist-cards-container">
            {today.length > 0 && (
              <div className="chat-session-group">
                <h4>Today</h4>
                <FlipMove>
                  {today.map((session) => (
                    <div key={session.id} className={`chat-session ${session.id === sessionID ? 'active' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation()
                        const target = e.target as Element; // Cast to Element to access `closest` method
                        if (!target.closest('.session-options, .editing-session-title')) {
                          setSessionID(session.id);
                          setSearchParams({ session_id: session.id });
                        }
                      }}
                    >
                      {editingSessionId === session.id ? (
                        <div className="editing-session-title">
                          <input
                            type="text"
                            id={`session-input-${session.id}`}
                            value={tempSessionTitle}
                            onChange={handleTitleChange}
                            onKeyDown={(e) => handleKeyDown(e, session.id)}
                            autoFocus
                          />
                          <i className="bi bi-check save-icon" onClick={(e) => {
                            e.stopPropagation(); // Prevents triggering session click when saving title
                            handleSaveTitle(session.id);
                          }} />
                          <i className="bi bi-x cancel-icon" onClick={(e) => {
                            e.stopPropagation(); // Prevents triggering session click when cancelling edit
                            handleCancelEdit();
                          }} />
                        </div>
                      ) : (
                        <>
                          <span className="title-span" title={session.title || `Chat ${session.id}`}>
                            {session.title || `Chat ${session.id}`}
                          </span>
                          <div className="session-options" onClick={(e) => e.stopPropagation()}>
                            <i className="bi bi-three-dots-vertical menu-icon"
                              id={`menu-icon-${session.id}`}
                              onClick={() => toggleMenu(session.id)}></i>
                            {activeMenu === session.id && (
                              <div className="dropdown-menu-history dropdown-menu" id={`menu-${session.id}`} ref={menuRef}>
                                <button className="dropdown-item-history dropdown-item" onClick={() => handleDelete(session.id)}>
                                  <i className="bi bi-trash"></i> Delete
                                </button>
                                <button className="dropdown-item-history dropdown-item" onClick={() => handleRename(session.id, session.title || `Chat ${session.id}`)}>
                                  <i className="bi bi-pencil"></i> Rename
                                </button>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>

                  ))}

                </FlipMove>
              </div>
            )}
            {lastWeek.length > 0 && (
              <div className="chat-session-group">
                <h4>Previous 7 Days</h4>
                <FlipMove>
                  {lastWeek.map((session) => (
                    <div
                      key={session.id}
                      className={`chat-session ${session.id === sessionID ? 'active' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation()
                        const target = e.target as Element; // Cast to Element to access `closest` method
                        if (!target.closest('.session-options, .editing-session-title')) {
                          setSessionID(session.id);
                          setSearchParams({ session_id: session.id });
                        }
                      }}
                    >
                      {editingSessionId === session.id ? (
                        <div className="editing-session-title">
                          <input
                            type="text"
                            id={`session-input-${session.id}`}
                            value={tempSessionTitle}
                            onChange={handleTitleChange}
                            onKeyDown={(e) => handleKeyDown(e, session.id)}
                            autoFocus
                          />
                          <i className="bi bi-check save-icon"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevents triggering session click when saving title
                              handleSaveTitle(session.id);
                            }}
                          />
                          <i className="bi bi-x cancel-icon"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevents triggering session click when cancelling edit
                              handleCancelEdit();
                            }}
                          />
                        </div>
                      ) : (
                        <>
                          <span className="title-span" title={session.title || `Chat ${session.id}`}>
                            {session.title || `Chat ${session.id}`}
                          </span>
                          <div className="session-options" onClick={(e) => e.stopPropagation()}>
                            <i className="bi bi-three-dots-vertical menu-icon"
                              id={`menu-icon-${session.id}`}
                              onClick={() => toggleMenu(session.id)}></i>
                            {activeMenu === session.id && (
                              <div className="dropdown-menu-history dropdown-menu" id={`menu-${session.id}`} ref={menuRef}>
                                <button className="dropdown-item-history dropdown-item" onClick={() => handleDelete(session.id)}>
                                  <i className="bi bi-trash"></i> Delete
                                </button>
                                <button className="dropdown-item-history dropdown-item" onClick={() => handleRename(session.id, session.title || `Chat ${session.id}`)}>
                                  <i className="bi bi-pencil"></i> Rename
                                </button>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </FlipMove>
              </div>
            )}

            {lastMonth.length > 0 && (
              <div className="chat-session-group">
                <h4>Previous 30 Days</h4>
                <FlipMove>
                  {lastMonth.map((session) => (
                    <div
                      key={session.id}
                      className={`chat-session ${session.id === sessionID ? 'active' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation()
                        const target = e.target as Element; // Cast to Element to access `closest` method
                        if (!target.closest('.session-options, .editing-session-title')) {
                          setSessionID(session.id);
                          setSearchParams({ session_id: session.id });
                        }
                      }}
                    >
                      {editingSessionId === session.id ? (
                        <div className="editing-session-title">
                          <input
                            type="text"
                            id={`session-input-${session.id}`}
                            value={tempSessionTitle}
                            onChange={handleTitleChange}
                            onKeyDown={(e) => handleKeyDown(e, session.id)}
                            autoFocus
                          />
                          <i className="bi bi-check save-icon"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevents triggering session click when saving title
                              handleSaveTitle(session.id);
                            }}
                          />
                          <i className="bi bi-x cancel-icon"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevents triggering session click when cancelling edit
                              handleCancelEdit();
                            }}
                          />
                        </div>
                      ) : (
                        <>
                          <span className="title-span" title={session.title || `Chat ${session.id}`}>
                            {session.title || `Chat ${session.id}`}
                          </span>
                          <div className="session-options" onClick={(e) => e.stopPropagation()}>
                            <i className="bi bi-three-dots-vertical menu-icon"
                              id={`menu-icon-${session.id}`}
                              onClick={() => toggleMenu(session.id)}></i>
                            {activeMenu === session.id && (
                              <div className="dropdown-menu-history dropdown-menu" id={`menu-${session.id}`} ref={menuRef}>
                                <button className="dropdown-item-history dropdown-item" onClick={() => handleDelete(session.id)}>
                                  <i className="bi bi-trash"></i> Delete
                                </button>
                                <button className="dropdown-item-history dropdown-item" onClick={() => handleRename(session.id, session.title || `Chat ${session.id}`)}>
                                  <i className="bi bi-pencil"></i> Rename
                                </button>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </FlipMove>
              </div>
            )}

            {(today.length === 0 && lastWeek.length === 0 && lastMonth.length === 0) && (
              <div className="no-chat-history">
                <p>No chat history yet. Get started using our chat services right away!</p>
              </div>
            )}
          </div>
      )}
      </div>
      <div className="chatContentWrap">
        <div className="chat-window">
          <div className="chat-content">
            <div className="message-list" ref={messageListRef}>
              {interleaveHistory(userMessages, systemMessages).map((message, index) => (
                <div key={index.toString()} className={`message ${message.type}-message`}>
                  {/* Render content first */}
                  {message.content && (
                    <>
                      <MarkdownMessage
                        message={message}
                        canViewCollections={hasCollectionPermissions || isAdmin}
                      />
                      <CopyButton textToCopy={message.content} messageType={message.type} />
                    </>
                  )}
                  {/* Then render the chart */}
                  {message.chart_data && !isObjectEmpty(message.chart_data) && (
                    <>
                      <PlotlyChart chartData={message.chart_data} />
                      <br />
                    </>
                  )}
                  {/* Then render the annotation */}
                  {message.annotation && !isObjectEmpty(message.annotation) && (
                    <>
                      <Annotation annotation={message.annotation} />
                      <br />
                    </>
                  )}
                  {/* Show spinner if no content, annotation, or chart */}
                  {!message.content && !message.chart_data && !message.annotation && (
                    <Spinner
                      style={{ margin: '-6px 0 0 6px' }}
                      animation="grow"
                      variant="secondary"
                    />
                  )}
                </div>
              ))}


            </div>
          </div>
          <div className="input-container-wrapper">
            {(hasCollectionPermissions || isAdmin) &&
              <div className="custom-dropdown">
                <div className="selected-collection-display" onClick={toggleDropdown}>
                  <span className="chatting-with">File Collection:</span>
                  {selectedCollection === 'entire-library' ? (
                    <>
                      <span className="collection-name">Entire Library</span>
                      <i className="bi bi-caret-up-fill"></i>
                    </>
                  ) : (
                    <>
                      <span className="collection-name">
                        {collections.find(col => col.id === selectedCollection)?.collection_name}
                      </span>
                      <i
                        className="bi bi-x"
                        onClick={handleClearSelection}
                      ></i>
                    </>
                  )}
                </div>
                {isOpen && (
                  <div className="dropdown-options">
                    <div
                      className="dropdown-option"
                      onClick={() => handleSelection('entire-library')}
                    >
                      Entire Library
                    </div>
                    {collections.map((collection) => (
                      <div
                        key={collection.id}
                        className="dropdown-option"
                        onClick={() => handleSelection(collection.id)}
                      >
                        {collection.collection_name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            }

            <div className="input-container" ref={chatBoxRef}>
              <div className="">
                <div className="selected-tool-display" onClick={toggleToolPopup}>
                  <div className="tool-icon-container">
                    <i className="bi bi-tools" style={{ "fontSize": "1.25rem" }}></i>
                    {selectedTools.length > 0 && (
                      <span className="selected-tools-count">{selectedTools.length}</span>
                    )}
                  </div>
                </div>
                {isToolPopupOpen && (
                  <div className="dropdown-tool-options" ref={toolPopupRef}>
                    {toolOptions.length > 0 ? (
                      toolOptions.map((tool) => (
                        <label
                          key={tool.value}
                          className={`dropdown-tool-option ${selectedTools.some((t) => t.value === tool.value) ? 'selected' : ''
                            }`}
                        >
                          <input
                            type="checkbox"
                            checked={selectedTools.some((t) => t.value === tool.value)}
                            onChange={() => handleToolToggle(tool)}
                            style={{ marginRight: '8px' }}
                          />
                          {tool.label}
                        </label>
                      ))
                    ) : (
                      <span>No tool options available</span>
                    )
                    }
                  </div>
                )}
              </div>
              <textarea
                ref={textareaRef}
                style={{ resize: 'none', overflowY: 'scroll' }} // Prevent manual resizing
                autoFocus
                className="message-input"
                placeholder="Type your message here..."
                value={userInput}
                onChange={handleUserInput}
                onKeyDown={(event) => {
                  if (event.key === "Enter" && !event.shiftKey) {
                    event.preventDefault(); // Prevent new line on Enter key
                    if (isLoading) {
                      return;
                    }
                    handleSendMessage();
                  }
                }}
              />
              {isLoading ? (
                // Render the "Stop" button with a stop icon when loading
                <button
                  className="send-button"
                  onClick={handleStopResponse}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <i className="bi bi-stop-circle-fill" style={{ fontSize: '1.5rem' }}></i>
                </button>
              ) : (
                // Render the "Send" button with a send icon when not loading
                <button
                  className="send-button"
                  onClick={handleSendMessage}
                  disabled={isLoading}
                  style={{
                    cursor: isLoading ? 'wait' : 'pointer',
                  }}
                >
                  <i className="bi bi-arrow-up-circle-fill" style={{ fontSize: '1.5rem' }}></i>
                </button>
              )}
            </div>
            <ErrorPopup
              error={backendError}
              errorMsg={backendErrorMsg}
              onIgnore={handleErrorIgnore}
              onReset={handleErrorReset}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatWindow;
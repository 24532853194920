import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useAuth } from "../../../context/UserState";
import IUser from "../../../types/user.type";
import './createProjectFile.css'
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useToast } from "../../../context/ToastProvider";

import CollectionService from '../../../services/collection.service';
import ProjectService from '../../../services/project.service';
import ProjectFileService from '../../../services/projectfile.service';
import ApiCollection from '../../../types/collection.type'; 
import ApiProject from '../../../types/project.type'; 
import ApiTemplateInstructionsObj from '../../../types/templateinstructionsobject.type'; 
import TemplateService from '../../../services/template.service'; 
import ApiTemplate from '../../../types/template.type'; 
import { Button, Alert, Spinner } from "react-bootstrap";

const CreateProjectFile: React.FC = () => {
  const { currentUser } = useAuth() as { currentUser: IUser | null };
  const location = useLocation();
  const { project_id } = location.state || {}
  const [project, setProject] = useState<ApiProject>();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const [collections, setCollections] = useState<ApiCollection[]>([]); 
  const [templates, setTemplates] = useState<ApiTemplate[]>([]); 
  const [templateObj, setTemplateObj] = useState<ApiTemplate>(); 
  const [templateInstructionsObj, setTemplateInstructionsObj] = useState<ApiTemplateInstructionsObj>()
   
  const [selectedProject, setSelectedProject] = useState(''); 
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [selectedCollection, setSelectedCollection] = useState<string>(''); 
  const [showCollection, setShowCollection] = useState<boolean>(false); 
  const [showProcessButton, setShowProcessButton] = useState<boolean>(false);
  const [requiresQuestions, setRequiresQuestions] = useState<boolean>(false); 
  
  const [projectsOptions, setProjectsOptions] = useState<{ value: string; label: string }[]>([]);
  const [templatesOptions, setTemplatesOptions] = useState<{ value: string; label: string }[]>([]);
  const [collectionsOptions, setCollectionsOptions] = useState<{ value: string; label: string }[]>([]); 
  

  useEffect(() => {
    if (project_id) {
      fetchProject();
      fetchTemplates();
      fetchCollections();
    }
  }, [currentUser, navigate])

  const fetchProject = async () => { 
    try { 
      const project = await ProjectService.getProject(project_id);
      setSelectedProject(project_id) 
      setProject(project)

      
    } catch (error) { 
      console.log(error); 
    } 
  };

  const fetchTemplates = async () => { 
    try { 
      const loadedTemplates = await TemplateService.getTemplates(); 
      
      const templateDDList = loadedTemplates.map((template: ApiTemplate) => ({ 
        value: template.id, 
        label: template.template_name, 
      })); 

      setTemplatesOptions(templateDDList); 
      setTemplates(loadedTemplates);
    } catch (error) { 
      console.log(error); 
    } 
  };

  const fetchCollections = async () => { 
    try { 
      const collections = await CollectionService.getCollections(); 
      const collectionDDList = collections.map((collection: ApiCollection) => ({ 
        value: collection.id, 
        label: collection.collection_name, 
      })); 

      setCollectionsOptions(collectionDDList); 
      setCollections(collections); 
    } catch (error) { 
      console.log(error); 
    } 
  };

  const handleTemplateChange = async (e: any) => {
    setShowProcessButton(false);
    if (typeof e === 'object' && e !== null && 'value' in e) {
      
      const template_id = e.value
      setSelectedTemplate(template_id);

      const templateObject = await TemplateService.getTemplate(template_id)
      setTemplateObj(templateObject)
      if (templateObject.file_type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        setIsLoading(true);
        setShowCollection(false);
        setRequiresQuestions(true);
        const templateInstructionsObject = await TemplateService.getTemplateInstructions(template_id)
        if (templateInstructionsObject) {
          templateInstructionsObject.project_id = project_id;
          templateInstructionsObject.project_name = (project?.project_name || '')
          templateInstructionsObject.template_id = template_id;
          templateInstructionsObject.template_name = (templateObject?.template_name || '')
          setTemplateInstructionsObj(templateInstructionsObject)
          setShowProcessButton(true);
        }
        setIsLoading(false);
      } else {
        setShowCollection(true);
        if (selectedCollection) {
            setShowProcessButton(true);
        } else {
          setShowProcessButton(false);
        }
        setRequiresQuestions(false);
      }
      

    } else {
      setSelectedTemplate('');
    }
  };

  const handleCollectionChange = (e: any) => {
    setShowProcessButton(false);
    if (typeof e === 'object' && e !== null && 'value' in e) {
      setSelectedCollection(e.value);
      setShowProcessButton(true);
    } else {
      setSelectedCollection('');
    }
  };

  const handleCancel = () => {
    navigate(`/projectstemplates`);
  };

  const validateFields = () => { 
    const errors = [];

    if (selectedTemplate === '') {
        errors.push('Please select a Template.');
    }
    if (selectedCollection === '' && !requiresQuestions) {
        errors.push('Please select a Collection.');
    }

    if (errors.length > 0) {
        setErrorMessage(errors.join('\n'));
        return false;
    }

    setErrorMessage(null); 
    return true;
  }; 

  const handleClearFields = () => {
    setSelectedProject('');
    setSelectedTemplate('');
    setSelectedCollection('');
  };

  const handleProcessTemplate = () => { 
    if (validateFields()) { 
        console.log("Processing template..."); 

        if (!requiresQuestions) {
          (async () => {
                        try {
                            await ProjectFileService.createProjectFile(selectedProject, selectedTemplate, selectedCollection);
                            console.log("Project file creation started");
                        } catch (error) {
                            console.error("Failed to initiate project file creation:", error);
                        }
                    })();

          

          addToast("success", "Project Template Processing", "This may take a few minutes. Check back later for the project status to be marked active for downloading.");
          
          setTimeout(() => {
            handleClearFields();
            navigate(`/projectstemplates`);
          }, 2000);
            
        } else {
          navigate(`/templatequestions`, { state: { templateInstructionsObj } });
        }
      
    }
  };

  return (
    <div className="createProjectFile-container">
      {currentUser && (
        <div className="createProjectFile-content">
          <div className="createProjectFile-header">
            <h3>Create Project File</h3>
          </div>
          <div className="createProjectFile-body">
            {errorMessage && (
                  <Alert variant="danger">
                      {errorMessage.split('\n').map((msg, index) => (
                          <div key={index}>{msg}</div> // Display each error message in a separate line
                      ))}
                  </Alert>
                )} 
            <div className="mb-4"> 
              <label><i><strong>Project:</strong></i>&nbsp;&nbsp;&nbsp;{project?.project_name}</label>
            </div>
            <div className="mb-2"> 
              <label><i><strong>Template:</strong></i></label> 
              <Select 
                value={templatesOptions.find((option) => option.value === selectedTemplate) || ''} 
                onChange={handleTemplateChange} 
                options={templatesOptions} 
                isClearable={true}
                menuPosition="fixed"
              /> 
            </div>
            {isLoading && (
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
            {showCollection && (
              
                <div className="mb-2"> 
                  <label><i><strong>Collection:</strong></i></label> 
                  <Select 
                    value={collectionsOptions.find((option) => option.value === selectedCollection) || ''} 
                    onChange={handleCollectionChange} 
                    options={collectionsOptions} 
                    isClearable={true}
                    menuPosition="fixed"
                  /> 
                </div>
              )}
              <br /> 
              
                
                <div className="mb-2 d-flex justify-content-end"> 
                  <Button variant="outline-primary" className="d-flex align-items-center ps-0 fs-6" onClick={handleCancel}> 
                    <i className="bi bi-x-circle fs-5 me-1"></i> Cancel
                  </Button> &nbsp;&nbsp;
                  {showProcessButton && (
                    <Button variant="outline-primary" className="d-flex align-items-center ps-0 fs-6" onClick={handleProcessTemplate}> 
                      <i className="bi bi-play fs-5 me-1"></i> Project Template 
                    </Button> 
                   )}
                </div>
              
           

          </div>
        </div>
      )}
    </div>
  );
};

export default CreateProjectFile;

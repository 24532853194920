import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/UserState";
import { BrandingSettings } from "../types/organization.type";
import FeedbackModalButton from "./feedbackmodal.component";
import { Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useChat } from "../context/ChatState";

const LionFederalBranding: BrandingSettings = {
  headerDisplayName: "LION FEDERAL",
};

const Header = () => {
  const { currentUser, organization, logOut } = useAuth();
  const { newChat } = useChat()

  const [brandingObject, setBrandingObject] = useState<BrandingSettings>(LionFederalBranding);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (organization == undefined) {
        setLoading(false);
      }
    }, 500);

    if (organization?.brandingSettings) {
      setBrandingObject(organization.brandingSettings);
      setLoading(false);
    }

    return () => clearTimeout(timeout);
  }, [organization]);


  const navigate = useNavigate();

  const handleLogoClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    newChat()
    navigate("/chat");
  };

  if (loading) {
    return (
      <nav className="navbar navbar-expand header">
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </nav>
    );
  }

  return (
    <nav className="navbar navbar-expand header">
      <Link to="/chat" onClick={handleLogoClick} className="navbar-brand">
        <h1 className="header-text-logo">{brandingObject.headerDisplayName}</h1>
      </Link>
      {currentUser && (
        <>
          <div className="navbar-nav ms-auto">
            <FeedbackModalButton />

            <li className="nav-item logout-link">
              <Button
                className="logout-button"
                onClick={(e) => {
                  e.preventDefault();
                  logOut();
                  setBrandingObject(LionFederalBranding);
                  navigate("/login");
                }}
              >
                Log Out
                <i className="bi bi-box-arrow-right ps-2"></i>
              </Button>
            </li>
          </div>
        </>
      )}
      {!currentUser && (
        <div className="navbar-nav ms-auto">
          <li className="nav-item">
            <Button
              variant={"outline-secondary"}
              onClick={(e) => {
                e.preventDefault();
                navigate("/login");
              }}
            >
              Login
            </Button>
          </li>
        </div>
      )}
    </nav>
  );
};

export default React.memo(Header);

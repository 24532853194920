  import React, { createContext, useContext, useState, ReactNode, useMemo, useEffect } from 'react';
  import CollectionService from '../services/collection.service';
  import ApiCollection from '../types/collection.type'

  type CollectionsContextType = {
    collections: ApiCollection[];
    selectedCollection: string; // New state for selected collection
    setSelectedCollection: (collectionId: string) => void;
    handleAddCollection: (collection: ApiCollection) => void;
    handleRemoveCollection: (collectionId: string) => void;
    handleUpdateCollection: (collection: ApiCollection) => void;
    fetchCollections: () => void;
    resetSelectedCollection: () => void;
  };

  const CollectionsContext = createContext<CollectionsContextType | undefined>(undefined);

  export const useCollections = () => {
    const context = useContext(CollectionsContext);
    if (!context) throw new Error('useCollections must be used within a CollectionsProvider');
    return context;
  };

  type ContextProviderProps = {
      children: ReactNode;
    };
    
    export const CollectionsProvider: React.FC<ContextProviderProps> = ({ children }) => {
      const [collections, setCollections] = useState<ApiCollection[]>([]);
      const [selectedCollection, setSelectedCollection] = useState<string>('entire-library');

      useEffect(() => {
        fetchCollections();
      }, []);

      const sortCollectionsAZ = (collections: ApiCollection[]) =>
        collections.sort((a, b) => a.collection_name.localeCompare(b.collection_name));
      
      const fetchCollections = async () => {
        try {
          const collections = await CollectionService.getCollections();
          const sortedCollections = sortCollectionsAZ(collections)
          setCollections(sortedCollections);
        } catch (error) {
          console.log(error);
        }
      };
    
      const handleAddCollection = (collection: ApiCollection) => {
        setCollections(prevCollections => [...prevCollections, collection]);
      };
    
      const handleRemoveCollection = (collectionId: string) => {
        setCollections(prevCollections => prevCollections.filter(collection => collection.id !== collectionId));
      };
    
      const handleUpdateCollection = (updatedCollection: ApiCollection) => {
        setCollections(prevCollections => 
          prevCollections.map(collection => 
            collection.id === updatedCollection.id ? updatedCollection : collection
          )
        );
      };

      const resetSelectedCollection = () => {
        setSelectedCollection('entire-library');
      };
    
      const value = useMemo(() => ({
        collections,
        selectedCollection, // Provide selectedCollection in context
        setSelectedCollection,
        handleAddCollection,
        handleRemoveCollection,
        handleUpdateCollection,
        fetchCollections,
        resetSelectedCollection
      }), [collections, selectedCollection]);
    
      return (
        <CollectionsContext.Provider value={value}>
          {children}
        </CollectionsContext.Provider>
      );
    };
    

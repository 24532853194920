import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Upload from './upload.component';

interface UploadModalProps {
  show: boolean;
  handleClose: () => void;
  onUploadSuccess: () => void;
}

const UploadModal: React.FC<UploadModalProps> = ({ show, handleClose, onUploadSuccess }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>File Uploader</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Upload handleClose={handleClose} onUploadSuccess={onUploadSuccess} />
      </Modal.Body>
    </Modal>
  );
};

export default UploadModal;

import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import RunTemplate from './projects.component';
import ManageTemplate from './templates.component';
import './projecttemplate.css'; 

const ProjectTemplate: React.FC = () => {
    const [key, setKey] = useState('Projects');
    return (
        <div className="template-panel-container">
        <h2 className="template-panel-title">Projects & Templates</h2>
        <Tabs activeKey={key} onSelect={(k) => k && setKey(k)} defaultActiveKey="Projects">
          <Tab eventKey="Projects" title="Projects">
            <RunTemplate  selectedTab={key} />
          </Tab>
          <Tab eventKey="manageTemplate" title="Templates">
            <ManageTemplate />
          </Tab>
        </Tabs>
      </div>
        
    );
}

export default ProjectTemplate;

import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

interface PlotlyChartProps {
    chartData: any;
}

const PlotlyChart: React.FC<PlotlyChartProps> = ({ chartData }) => {
    const [plotData, setPlotData] = useState<any[]>([]);
    const [layoutConfig, setLayoutConfig] = useState<any>({});
    useEffect(() => {
        try {
            const { plotly_config, data } = chartData;
            const { dataKeys, trace, layout } = plotly_config;
            const xKey = dataKeys.x;
            const yKey = dataKeys.y;
            const chartDataAdjusted: any = {};
            chartDataAdjusted[xKey] = data["x"];
            chartDataAdjusted[yKey] = data["y"];

            const xData = chartDataAdjusted[xKey];
            const yData = chartDataAdjusted[yKey];

            if (!xData || !yData) {
                throw new Error('Data keys not found in data.');
            }

            const traceData = {
                ...trace,
                x: xData,
                y: yData,
                // marker: {
                //     color: 'red', // Initial color
                // },
                // line: {
                //     color: 'blue', // Initial color
                // },
            };

            setPlotData([traceData]);
            setLayoutConfig(layout);
        } catch (err: any) {
            console.error('Error initializing chart:', err);
        }
    }, [chartData]);

    const colors = [['red', 'blue'], ['green', 'blue'], ['orange', 'purple'], ['green', 'grey'], ['aqua', 'pink']];

    const changeColors = () => {
        setPlotData(prevData => {
            const currentMarkerColor = prevData[0].marker.color;
            const currentLineColor = prevData[0].line.color;
            let currentIndex = colors.findIndex(colorPair =>
                colorPair[0] === currentMarkerColor && colorPair[1] === currentLineColor
              );
            if (currentIndex === -1) {
            currentIndex = 0;
            }
            const nextIndex = (currentIndex + 1) % colors.length;
            const nextColorPair = colors[nextIndex];
            return prevData.map(trace => ({
                ...trace,
                marker: {
                    ...trace.marker,
                    color: nextColorPair[0],
                },
                line: {
                    ...trace.line,
                    color: nextColorPair[1],
                },
            }));
        });
    };

    const customIcon = {
        width: 16,
        height: 16,
        path: 'M8 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m4 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M5.5 7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3 M16 8c0 3.15-1.866 2.585-3.567 2.07C11.42 9.763 10.465 9.473 10 10c-.603.683-.475 1.819-.351 2.92C9.826 14.495 9.996 16 8 16a8 8 0 1 1 8-8m-8 7c.611 0 .654-.171.655-.176.078-.146.124-.464.07-1.119-.014-.168-.037-.37-.061-.591-.052-.464-.112-1.005-.118-1.462-.01-.707.083-1.61.704-2.314.369-.417.845-.578 1.272-.618.404-.038.812.026 1.16.104.343.077.702.186 1.025.284l.028.008c.346.105.658.199.953.266.653.148.904.083.991.024C14.717 9.38 15 9.161 15 8a7 7 0 1 0-7 7',
        transform: 'matrix(1, 0, 0, -1, 0, 16)',
      };


    return (
        <Plot
            data={plotData}
            layout={layoutConfig}
            config={{
                displaylogo: false,
                modeBarButtonsToAdd: [{
                    title: 'Change Colors',
                    name: "color changer",
                    icon: customIcon, // Replace with your desired icon
                    click: changeColors,
                }],
            }}
            style={{ width: '100%', height: '100%' }}
        />
    );
};

export default PlotlyChart;

import { SyntheticEvent, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAuth } from "../context/UserState";
import { useChat } from "../context/ChatState";
import { clearConfigCache } from "prettier";
import { ApiFeedback } from "../types/feedback.type";
 
import FeedbackService from "../services/feedback.service";

function FeedbackModalButton() {
  const [show, setShow] = useState(false);
  const { currentUser, organization} = useAuth()
  const { sessionID } = useChat(); 

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    const target = event.target as typeof event.target & {
      0: { value: string };
      1: { value: string };
    };
    const topic = target[0].value
    const message = target[1].value
    
    const feedbackData: ApiFeedback = {
      topic,
      message,
      session_id: sessionID,
    };

    try {

      await FeedbackService.createFeedback(feedbackData);
      handleClose();
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
     
 
  };


  return (
    <>
      <Button variant={"outline-secondary"} className="feedback-button" onClick={handleShow}>
        Feedback<i style={{"marginLeft": "8px"}} className="bi bi-chat-left-dots"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Feedback Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
          <Form.Label>Topic</Form.Label>
            <Form.Select name="topic" aria-label="Default select example">
              <option value="general">General</option>
              <option value="results">Chat Results</option>
              <option value="other">Other</option>
            </Form.Select>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Provide Feedback Here</Form.Label>
              <Form.Control name="message" as="textarea" rows={3} />
            </Form.Group>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                Submit
              </Button>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FeedbackModalButton;
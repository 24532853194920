import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import ICollection from "../../../types/collection.type";
import CollectionService from '../../../services/collection.service';

interface CollectionModalProps {
  collectionToEdit: ICollection | null;
  onSaveSuccess: (collection: ICollection) => void;
  onClose: () => void;
  onShow: boolean;
}

const CollectionModal: React.FC<CollectionModalProps> = ({ collectionToEdit, onSaveSuccess, onClose, onShow }) => {
  const [collectionName, setCollectionName] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const collectionNameRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (collectionToEdit) {
      setCollectionName(collectionToEdit.collection_name);
    } else {
      setCollectionName(''); // Reset to blank when creating a new collection
    }
  }, [collectionToEdit]);

  useEffect(() => {
    if (onShow && collectionNameRef.current) {
      collectionNameRef.current.focus();
    }
  }, [onShow]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSave();
      }
    };

    if (formRef.current) {
      formRef.current.addEventListener('keypress', handleKeyPress);
    }

    return () => {
      if (formRef.current) {
        formRef.current.removeEventListener('keypress', handleKeyPress);
      }
    };
  }, [collectionName]);

  const createCollection = async (collection: ICollection) => {
    try {
      const response = await CollectionService.createCollection(collection);
      const newCollection = { ...collection, id: response.id };
      onSaveSuccess(newCollection); // Pass the new collection to onSaveSuccess
    } catch (error) {
      console.error('Error creating collection:', error);
    } finally {
      onClose();
      setCollectionName('')
    }
  };

  const updateCollection = async (collection: ICollection) => {
    try {
      const updatedCollection = await CollectionService.updateCollection(collection);
      onSaveSuccess(updatedCollection); // Pass the updated collection to onSaveSuccess
    } catch (error) {
      console.error('Error updating collection:', error);
    } finally {
      onClose();
    }
  };

  const handleSave = async () => {
    if (collectionName.length > 20) {
      setErrorMessage('Collection name must be 20 characters or fewer.');
      return;
    }

    setErrorMessage(null);

    if (collectionToEdit) {
      const updatedCollection: ICollection = {
        ...collectionToEdit,
        collection_name: collectionName,
      };
      await updateCollection(updatedCollection);
    } else {
      const newCollection: ICollection = {
        id: '',
        collection_name: collectionName,
        assigned_file_count: 0
      };
      await createCollection(newCollection);
    }
  };

  return (
    <>
      <Modal show={onShow} onHide={onClose} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => e.stopPropagation()}>
        <Modal.Header closeButton>
          <Modal.Title>{collectionToEdit ? 'Edit Collection' : 'Create Collection'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef}>
            <Form.Group controlId="collectionName">
              <Form.Label>Collection Name</Form.Label>
              <Form.Control
                type="text"
                ref={collectionNameRef}
                value={collectionName}
                onChange={(e) => setCollectionName(e.target.value)}
              />
              <Form.Text className="text-muted">
                Maximum 20 characters.
              </Form.Text>
            </Form.Group>
          </Form>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e) => {e.stopPropagation(); onClose();}}>
            Cancel
          </Button>
          <Button variant="primary" onClick={(e) => {e.stopPropagation(); handleSave();}}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CollectionModal;

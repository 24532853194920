import { useEffect, useRef } from 'react';

const useAutosizeTextArea = (value: string) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Reset the height to auto to correctly calculate scrollHeight
      textarea.style.height = '0px';
      const scrollHeight = textarea.scrollHeight;
      // Set the height to match the scrollHeight (auto size the textarea)
      textarea.style.height = `${scrollHeight}px`;
    }
  }, [value]); // This effect runs whenever the value changes

  return textareaRef;
};

export default useAutosizeTextArea;

import axios from "axios";
import authHeader from "./auth-header";
import { API_BASE_URL } from "../constants";

class ChatService {
  private baseUrl = API_BASE_URL;

  async getSessions(): Promise<any[]> {
    try {
      const response = await axios.get(`${this.baseUrl}chatsession`, { headers: authHeader() });
      return response.data.sessions;
    } catch (error) {
      console.error("Error fetching chat sessions:", error);
      throw error;
    }
  }

  async getConversation(sessionId: string): Promise<any[]> {
    try {
      const response = await axios.get(`${this.baseUrl}chatsession/${sessionId}/history`, { headers: authHeader() });
      return response.data.conversation;
    } catch (error) {
      console.error("Error fetching conversation:", error);
      throw error;
    }
  }
   
  // Updated to use the general PUT endpoint
  async updateChatSession(sessionId: string, newTitle: string): Promise<any> {
    try {
      const response = await axios.put(
        `${this.baseUrl}chatsession/${sessionId}`, // General update endpoint
        { title: newTitle }, // Payload includes the new title
        { headers: authHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating chat session:", error);
      throw error;
    }
  }
  async deleteChatSession(sessionId: string): Promise<any> {
    try {
      const response = await axios.delete(`${this.baseUrl}chatsession/${sessionId}`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting chat session:", error);
      throw error;
    }
  }
  async deleteAllChatSessions(): Promise<any> {
    try {
      const response = await axios.delete(`${this.baseUrl}chatsession`, {
        headers: authHeader(),
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting all chat sessions:", error);
      throw error;
    }
  }
  
}

export default new ChatService();

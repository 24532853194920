import { useEffect, useState } from "react";
import { useAuth } from "../../context/UserState";
import ResetPasswordModal from "../ResetPasswordModal.component";
import IUser from "../../types/user.type";
import './profile.css'
import { useNavigate } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import { useToast } from '../../context/ToastProvider'; // Import the Toast context
import ChatService from "../../services/chat.service";

const Profile: React.FC = () => {
  const { currentUser } = useAuth() as { currentUser: IUser | null };
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false); // State for delete modal
  const navigate = useNavigate(); // Initialize the navigate function
  const { addToast } = useToast(); // For displaying toast messages

  useEffect(() => {
    const isSSOUser = currentUser?.type?.includes("sso");

    if (isSSOUser) {
      navigate("/chat"); // Redirect to the chat page if the user is an SSO user
    }
  }, [currentUser, navigate]);

  const handleResetPasswordClick = () => {
    setShowPasswordModal(true);
  };

  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
  };

  const handleDeleteChatHistoryClick = () => {
    setShowDeleteModal(true); // Show the delete confirmation modal
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false); // Close the delete confirmation modal
  };

  const handleConfirmDelete = async () => {
    try {
       
      const response = await ChatService.deleteAllChatSessions();
      // Assuming deletion is successful, show a toast notification
      addToast('success', 'Chat History Deleted', 'All your chat history has been deleted successfully.');
    } catch (error) {
      console.error('Error deleting chat history:', error);
      addToast('error', 'Deletion Failed', 'An error occurred while deleting your chat history. Please try again.');
    } finally {
      setShowDeleteModal(false); // Close the modal after action
    }
  };

  return (
    <div className="profile-container">
      {currentUser && (
        <div className="profile-content">
          <div className="profile-header">
            <h2>My Profile</h2>
          </div>
          <div className="profile-body">
            <div className="profile-info">
              <div>
                <i className="bi bi-person-circle profile-icon"></i>
              </div>
              <div className="profile-text">
                <h3 className="profile-name">{currentUser.fname} {currentUser.lname}</h3>
                <p className="profile-email">{currentUser.email}</p>
              </div>
            </div>
            <div className="profile-details">
  
            </div>
            <div className="mt-4">
              <button className="btn btn-primary me-2" onClick={handleResetPasswordClick}>
                Reset Password
              </button>
              <button className="btn btn-danger" onClick={handleDeleteChatHistoryClick}>
                Delete All Chat History
              </button>
            </div>
          </div>
        </div>
      )}
      <ResetPasswordModal
        show={showPasswordModal}
        handleClose={handleClosePasswordModal}
        currentUser={currentUser}
      />

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete all your chat history? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Profile;

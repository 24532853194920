import React, { createContext, useState, useEffect, useContext } from "react";
import FileService from "../services/file.service";
import { useToast } from "./ToastProvider";
import ApiFile from "../types/file.type";
import { useAuth } from "./UserState";

interface PollingContextType {
    pollingList: ApiFile[];
    pollFiles: (files: ApiFile[]) => void;
    addFileToPollingList: (file: ApiFile) => void; // Update to accept an entire ApiFile object
}

const FETCH_FILES_INTERVAL = 60000;
const POLLING_FILES_INTERVAL = 6000;

const FileLibraryPollingContext = createContext<PollingContextType | undefined>(undefined);

export const usePolling = () => {
    const context = useContext(FileLibraryPollingContext);
    if (!context) {
        throw new Error("usePolling must be used within a FileLibraryPollingProvider");
    }
    return context;
};

export const FileLibraryPollingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [pollingList, setPollingList] = useState<ApiFile[]>([]);
    const { currentUser } = useAuth()
    const { addToast } = useToast();

    const pollFiles = (files: ApiFile[]) => {
        setPollingList((prevList) => [
            ...prevList,
            ...files.filter(file => !prevList.some(f => f.id === file.id)) 
        ]);
    };

    const addFileToPollingList = (file: ApiFile) => {
        if (file && !pollingList.some(f => f.id === file.id)) {
            setPollingList((prevList) => [...prevList, file]);
        } 
    };

    const pollFilesStatus = async () => {
        if (!currentUser || pollingList.length === 0) return;

        const fileIds = pollingList.map((file) => file.id);
        try {
            const updatedFiles: ApiFile[] = await FileService.getFilteredFiles(fileIds);
            updatedFiles.forEach((updatedFile) => {
                if (updatedFile.status === "Active") {
                    setPollingList((prevList) => prevList.filter((f) => f.id !== updatedFile.id));
                    addToast("success", "File Ready", `${updatedFile.filename} is now Active and ready to use.`);
                }
            });
        } catch (error) {
            console.error("Error polling file statuses:", error);
        }
    };

    const fetchFiles = async () => {
        if (!currentUser) return;
        try {
            const files = await FileService.getFiles();
            const nonActiveFiles = files.filter((file) => file.status !== "Active");
            setPollingList(nonActiveFiles);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!currentUser) return;
        fetchFiles();
        const fetchIntervalId = setInterval(fetchFiles, FETCH_FILES_INTERVAL);

        return () => clearInterval(fetchIntervalId);
    }, [currentUser]);

    useEffect(() => {
        if (!currentUser) return;
        const pollingIntervalId = setInterval(pollFilesStatus, POLLING_FILES_INTERVAL);

        return () => clearInterval(pollingIntervalId);
    }, [pollingList, currentUser]);

    const contextValue = React.useMemo(() => ({
        pollingList,
        pollFiles,
        addFileToPollingList,
    }), [pollingList, pollFiles, addFileToPollingList]
    );

    return (
        <FileLibraryPollingContext.Provider value={contextValue}>
            {children}
        </FileLibraryPollingContext.Provider>
    );
};

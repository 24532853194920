import React, { useState } from "react";
import "./sidebar.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/UserState";
import { useChat } from "../../context/ChatState";
import UploadModal from "../upload/uploadmodal.component";
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';

interface SidebarProps {
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar: React.FC<SidebarProps> = ({ isExpanded, setIsExpanded }) => {
  const { currentUser } = useAuth();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { newChat } = useChat();

  const isAdmin = currentUser?.permissions?.includes("admin");
  const isOrgAdmin = currentUser?.permissions?.includes("org-admin");
  const hasProjectsTemplates = currentUser?.permissions?.includes("projecttemplates");
  const isSSOUser = currentUser?.type?.includes("sso");

  const handleUploadSuccess = () => {
    return;
  };

  return (
    <div className={`sidebar ${isExpanded ? "expanded" : "collapsed"}`}>
      <div
        className="expand-icon-container"
        onClick={() => setIsExpanded(!isExpanded)}
        data-tooltip-id={!isExpanded ? "expand-tooltip" : "collapse-tooltip"}
        data-tooltip-content={!isExpanded ? "Expand Sidebar" : "Collapse Sidebar"}
      >
        <i className={!isExpanded ? "bi-arrow-right-short" : "bi-arrow-left-short"} style={{ fontSize: "1rem", display: "flex" }}></i>
      </div>

      <ul style={{ marginTop: "1.25rem" }}>
        <div>
          <li>
            <Link
              to={"/chat"}
              className="primary-button"
              onClick={newChat}
              data-tooltip-id={!isExpanded ? "new-chat-tooltip" : undefined}
              data-tooltip-content={!isExpanded ? "Start a new chat" : undefined}
            >
              <div className="button-container">
                <i className="bi bi-pencil-square"></i>
                <span className="text">New chat</span>
              </div>
            </Link>
          </li>
          <li>
            <Link
              to={"/library"}
              className="primary-button"
              data-tooltip-id={!isExpanded ? "file-library-tooltip" : undefined}
              data-tooltip-content={!isExpanded ? "View your file library" : undefined}
            >
              <div className="button-container">
                <i className="bi bi-collection"></i>
                <span className="text">File Library</span>
              </div>
            </Link>
          </li>
          {(isAdmin || isOrgAdmin) && (
            <li>
              <div
                className="primary-button"
                onClick={() => setShowUploadModal(true)}
                data-tooltip-id={!isExpanded ? "upload-files-tooltip" : undefined}
                data-tooltip-content={!isExpanded ? "Upload new files" : undefined}
              >
                <div className="button-container">
                  <i className="bi bi-plus-circle"></i>
                  <span className="text">Upload Files</span>
                </div>
              </div>
            </li>
          )}
          {(isAdmin || hasProjectsTemplates) && (
            <li>
              <Link
                to={"/projectstemplates"}
                className="primary-button"
                data-tooltip-id={!isExpanded ? "projects-templates-tooltip" : undefined}
                data-tooltip-content={!isExpanded ? "Manage projects and templates" : undefined}
              >
                <div className="button-container">
                  <i className="bi bi-clipboard-data"></i>
                  <span className="text">Projects & Templates</span>
                </div>
              </Link>
            </li>
          )}
        </div>
        {!isSSOUser && (
          <div className="admin-header-container">
            <div className={`thin-line ${isExpanded ? "" : "collapsed"}`}></div>
          </div>
        )}

        <div className={`settings-list-container ${isExpanded ? "expanded" : "collapsed"}`}>
          <>
            {!isSSOUser && <p className="text settings-header">SETTINGS</p>}
            {!isSSOUser && (
              <li>
                <Link
                  to={"/profile"}
                  className="secondary-button"
                  data-tooltip-id={!isExpanded ? "my-profile-tooltip" : undefined}
                  data-tooltip-content={!isExpanded ? "View your profile" : undefined}
                >
                  <div className="button-container">
                    <i className="bi bi-person-circle"></i>
                    <span className="text">My Profile</span>
                  </div>
                </Link>
              </li>
            )}
            {isAdmin && (
              <>
                <li>
                  <Link
                    to={"/chat"}
                    className="secondary-button"
                    data-tooltip-id={!isExpanded ? "settings-tooltip" : undefined}
                    data-tooltip-content={!isExpanded ? "Adjust settings" : undefined}
                  >
                    <div className="button-container">
                      <i className="bi bi-gear"></i>
                      <span className="text">Settings</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/admin"}
                    className="secondary-button"
                    data-tooltip-id={!isExpanded ? "admin-tooltip" : undefined}
                    data-tooltip-content={!isExpanded ? "Admin panel" : undefined}
                  >
                    <div className="button-container">
                      <i className="bi bi-shield-lock"></i>
                      <span className="text">Admin</span>
                    </div>
                  </Link>
                </li>
              </>
            )}
          </>
          <li></li>
        </div>
      </ul>
      <UploadModal
        show={showUploadModal}
        handleClose={() => setShowUploadModal(false)}
        onUploadSuccess={handleUploadSuccess}
      />

      {!isExpanded && (
        <>
          <ReactTooltip id="expand-tooltip" place="right" />
          <ReactTooltip id="collapse-tooltip" place="right" />
          <ReactTooltip id="new-chat-tooltip" place="right" />
          <ReactTooltip id="file-library-tooltip" place="right" />
          <ReactTooltip id="upload-files-tooltip" place="right" />
          <ReactTooltip id="projects-templates-tooltip" place="right" />
          <ReactTooltip id="my-profile-tooltip" place="right" />
          <ReactTooltip id="settings-tooltip" place="right" />
          <ReactTooltip id="admin-tooltip" place="right" />
        </>
      )}
      {isExpanded && (
        <>
          <ReactTooltip id="collapse-tooltip" place="right" />
        </>
      )}
    </div>
  );
};

export default Sidebar;

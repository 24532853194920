import axios from "axios";
import authHeader from "./auth-header";
import { API_BASE_URL } from "../constants";
import ApiProjectFile from "../types/projectfile.type";
import ProjectService from "./project.service"
import TemplateService from "./template.service"
import CollectionService from "./collection.service"
import ApiTemplateInstructionsObject from "../types/templateinstructionsobject.type";

class ProjectFileService {
    private baseUrl = API_BASE_URL + 'projectfile';
    async getProject(projectfile_id: string): Promise<ApiProjectFile> { //use an id from org instead of whole org obj
      const response = await axios.get(`${this.baseUrl}/${projectfile_id}`,{ headers: authHeader() });
      return response.data;
    }
    async getProjectFiles(): Promise<ApiProjectFile[]> {
      const response = await axios.get(`${this.baseUrl}/getprojectfiles`, { headers: authHeader() });

      let projectfiles = response.data
      let projects = await ProjectService.getProjects();
      let templates = await TemplateService.getTemplates();
      let collections = await CollectionService.getCollections();

      const enhancedProjectFiles = projectfiles.map((file: ApiProjectFile) => {
        const project = projects.find((p) => p.id === file.project_id);
        const template = templates.find((t) => t.id === file.template_id);
        const collection = collections.find((c) => c.id === file.collection_id);
        
        // Format the created_date to mm/dd/yyyy hh:mm if it exists
        const get_display_date = file.created_date
          ? new Intl.DateTimeFormat('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            }).format(new Date(file.created_date))
          : "N/A"; // Fallback if no created_date is available
  
        return {
          ...file,
          project_name: project?.project_name || "Unknown Project",
          template_name: template?.template_name || "Unknown Template",
          collection_name: collection?.collection_name || "Unknown Collection",
          created_date_display: get_display_date, // Assign the formatted date to create_date_display
        };
      });

      return enhancedProjectFiles;
    }

    async createProjectFile(project_id: string, template_id: string, collection_id: string): Promise<string> {
      try {
        const response = await axios.post(`${this.baseUrl}`, 
          {},
          { 
            params: { 
              project_id: project_id, 
              template_id: template_id, 
              collection_id: collection_id 
            },
            headers: authHeader(),
          }
        );
      
        return response.data;
      } catch (error) {
        console.error("Error creating project file:", error);
        throw error;
      }
    }

    async processTemplateInstructions(templateInstructionsObj: ApiTemplateInstructionsObject): Promise<string> {
      const template_id = templateInstructionsObj.template_id
      const response = await axios.post(this.baseUrl + "/processinstructions", templateInstructionsObj, { headers: authHeader() });
      return response.data;
    }
  
    async updateProject(projectfile: ApiProjectFile): Promise<ApiProjectFile> {
      const response = await axios.put(`${this.baseUrl}/${projectfile.id}`, projectfile, { headers: authHeader() });
      return response.data;
    }
    
    async deleteProjectFile(projectfile: ApiProjectFile): Promise<ApiProjectFile[]> {
      try {
        const response = await axios.delete(`${this.baseUrl}/${projectfile.id}`, { headers: authHeader() });
        return response.data;
      } catch (error) {
        console.error("Error fetching project file:", error);
        throw error; 
      }
    }

    async getProjectFileDownloadURL(projectfile_id: string): Promise<string> {
      try {
        const response = await axios.get(`${this.baseUrl}/${projectfile_id}/download-url`, {
          headers: authHeader(),
        });
        return response.data.presigned_url;
      } catch (error) {
        console.error("Error getting presigned URL:", error);
        throw error; 
      }
    }
}
export default new ProjectFileService();

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTable, useSortBy, Column } from 'react-table';
import { Table, Button, Modal, InputGroup, FormControl, Row, Col } from 'react-bootstrap';
import FeedbackService from '../../services/feedback.service';
import UserService from '../../services/user.service';
import OrganizationService from '../../services/organization.service';
import ChatService from '../../services/chat.service'; // Import ChatService
import { ApiFeedback} from '../../types/feedback.type';
import IUser from '../../types/user.type';
import {Message}  from '../../types/message.type';
import { ApiOrganization } from '../../types/organization.type';

interface DetailedFeedback {
  feedback: ApiFeedback;
  user: IUser | null;
  organization: ApiOrganization | null;
  messages: Message[] | null;
}

interface FeedbackRow {
  date: string;
  time: string;
  email: string;
  organization: string;
  topic: string;
  id?: string;
  user_id?: string;
  org_id?: string;
  timestamp?: Date;
}

const FeedbackComponent: React.FC = () => {
  const [feedbacks, setFeedbacks] = useState<ApiFeedback[]>([]);
  const [detailedFeedback, setDetailedFeedback] = useState<DetailedFeedback | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState<{ [key: string]: IUser }>({});
  const [organizations, setOrganizations] = useState<{ [key: string]: ApiOrganization }>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [feedbacksData, usersData, organizationsData] = await Promise.all([
          FeedbackService.getFeedbacks(),
          UserService.getUsers(), // Assuming a method to fetch all users
          OrganizationService.getOrganizations(), // Assuming a method to fetch all organizations
        ]);

        const usersMap = usersData.reduce((map: { [key: string]: IUser }, user: IUser) => {
          if (user.id) {
            map[user.id] = user;
          }
          return map;
        }, {});

        const organizationsMap = organizationsData.reduce((map: { [key: string]: ApiOrganization }, org: ApiOrganization) => {
          if (org.id) {
            map[org.id] = org;
          }
          return map;
        }, {});

        setFeedbacks(feedbacksData);
        setUsers(usersMap);
        setOrganizations(organizationsMap);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, []);

  const handleView = useCallback(async (feedback: ApiFeedback) => {
    const user = users[feedback.user_id!] || null;
    const organization = organizations[feedback.org_id!] || null;
    try {
      const messages = await ChatService.getConversation(feedback.session_id);
      setDetailedFeedback({ feedback, user, organization, messages });
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching conversation:', error);
    }
  }, [users, organizations]);

  const formatDate = (timestamp: Date) => {
    return new Date(timestamp).toLocaleDateString();
  };

  const formatTime = (timestamp: Date) => {
    return new Date(timestamp).toLocaleTimeString();
  };

  const data: FeedbackRow[] = useMemo(() => {
    return feedbacks.map(feedback => ({
      ...feedback,
      date: feedback.timestamp ? formatDate(feedback.timestamp) : 'N/A',
      time: feedback.timestamp ? formatTime(feedback.timestamp) : 'N/A',
      email: users[feedback.user_id!]?.email || 'N/A',
      organization: organizations[feedback.org_id!]?.name || 'N/A',
    }));
  }, [feedbacks, users, organizations]);

  const columns: Column<FeedbackRow>[] = useMemo(() => [
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Time',
      accessor: 'time',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Organization',
      accessor: 'organization',
    },
    {
      Header: 'Topic',
      accessor: 'topic',
    },
    {
      Header: 'Actions',
      Cell: ({ row }: any) => (
        <i className="bi bi-eye fs-5" onClick={() => handleView(row.original)}></i>
      )
    }
  ], [handleView]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable<FeedbackRow>({ columns, data }, useSortBy);

  return (
    <div className='container mt-4'>
      <h3 className="mb-4">Feedback</h3>
      <Table hover {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps((column as any).getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {(column as any).isSorted ? ((column as any).isSortedDesc ? ' ▼' : ' ▲ ') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" dialogClassName="modal-90w">
        <Modal.Header closeButton>
          <Modal.Title>Feedback Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {detailedFeedback && (
            <Row>
              <Col md={6}>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Date</InputGroup.Text>
                  <FormControl value={formatDate(detailedFeedback.feedback.timestamp!)} readOnly />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Time</InputGroup.Text>
                  <FormControl value={formatTime(detailedFeedback.feedback.timestamp!)} readOnly />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Email</InputGroup.Text>
                  <FormControl value={detailedFeedback.user?.email || 'N/A'} readOnly />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Organization</InputGroup.Text>
                  <FormControl value={detailedFeedback.organization?.name || 'N/A'} readOnly />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Topic</InputGroup.Text>
                  <FormControl value={detailedFeedback.feedback.topic} readOnly />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Message</InputGroup.Text>
                  <FormControl as="textarea" rows={7} value={detailedFeedback.feedback.message || 'N/A'} readOnly />
                </InputGroup>
              </Col>
              <Col md={6}>
                <h5 className="mb-3">Chat</h5>
                <div className="overflow-auto border rounded p-3 bg-light" style={{ height: '500px' }}>
                  {detailedFeedback.messages?.map((message, index) => (
                    <div key={index} className={`mb-3 p-3 rounded ${message.type === 'human' ? 'bg-secondary text-white' : 'bg-info text-dark'}`}>
                      <strong>{message.type}:</strong> <span>{message.content}</span>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FeedbackComponent;

import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { ApiOrganization, SsoIntegration, IngestionConfig } from "../../types/organization.type";
import { v4 as uuidv4 } from "uuid";
import OrganizationService from '../../services/organization.service';
import Select from "react-select";
import Accordion from 'react-bootstrap/Accordion';
import { Controlled as CodeMirror } from "react-codemirror2";
import styles from "./admin.module.css"
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/javascript/javascript";

interface OrganizationModalProps {
  organizationToEdit: ApiOrganization | null;
  onSaveSuccess: (organization: ApiOrganization) => void;
  onClose: () => void;
  onShow: boolean;
}

interface EditableSsoIntegration extends SsoIntegration {
  tempId?: number; // Temporary ID for React key purposes
}

const OrganizationModal: React.FC<OrganizationModalProps> = ({ organizationToEdit, onSaveSuccess, onClose, onShow }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const organizationNameRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [organizations, setOrganizations] = useState<ApiOrganization[]>([]);
  const [currentOrg, setCurrentOrg] = useState<ApiOrganization | null>(null);
  const [ssoIntegrations, setSsoIntegrations] = useState<EditableSsoIntegration[]>([]);
  const [metaError, setMetaError] = useState<string | null>(null);
  const [tensorFieldOptions, setTensorFieldOptions] = useState<any[]>([]);
  const [isMetadataEnabled, setIsMetadataEnabled] = useState(currentOrg?.ingestionConfig?.metadataEnabled ?? false);
  const modalRef = useRef(null);
  const [allTools, setAllTools] = useState<any[]>([]);
  const [selectedTools, setSelectedTools] = useState<any[]>([]);

  useEffect(() => {
    if (currentOrg?.ingestionConfig?.metadataJson) {
      parseMetadataJson(currentOrg.ingestionConfig.metadataJson);
    }
  }, [currentOrg?.ingestionConfig?.metadataJson]);

  useEffect(() => {
    if (organizationToEdit) {
      startEditing(organizationToEdit)
    } else {
      const newOrganization: ApiOrganization = {
        id: '',
        name: ''
      }; 
      organizationToEdit = newOrganization;
      startEditing(organizationToEdit)
    }
  }, [organizationToEdit]);

  useEffect(() => {
    if (onShow && organizationNameRef.current) {
      organizationNameRef.current.focus();
    }

    OrganizationService.getAllTools().then((toolsData) => {
      const tools = toolsData.tools.map((tool: any) => ({
        value: tool.tool_name,
        label: `${tool.tool_name} - ${tool.description}`,
      }));
      setAllTools(tools);
    }).catch((error) => {
      console.error('Error fetching tools:', error);
      setErrorMessage('Error fetching available tools.');
    });
  }, [onShow]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        createOrUpdateOrganization();
      }
    };

    if (formRef.current) {
      formRef.current.addEventListener('keypress', handleKeyPress);
    }

    return () => {
      if (formRef.current) {
        formRef.current.removeEventListener('keypress', handleKeyPress);
      }
    };
  }, [organizationToEdit]);

  // Event handler to stop event propagation
  const handleStopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const modelOptions = [
    { value: "gpt-4o", label: "gpt-4o" },
    { value: "gpt-4-turbo", label: "gpt-4-turbo" },
    { value: "gpt-4", label: "gpt-4" },
  ];

  const createOrUpdateOrganization = async () => {
    const orgData = {
      ...currentOrg!,
      ssoIntegrations: ssoIntegrations.filter((sso) => sso.provider && sso.externalId && sso.secretKey),
    };

    if (currentOrg?.id) {
      try {
      // Updating an existing organization
      const updatedOrg = await OrganizationService.updateOrganization(orgData);
      onSaveSuccess(updatedOrg);
      setOrganizations(organizations.map((o) => (o.id === updatedOrg.id ? updatedOrg : o)));
      } catch (error) {
        console.error('Error adding organization:', error);
      } finally {
        onClose();
      }
    } else {
      

      try {
        // Creating a new organization
        const newOrganization = await OrganizationService.createOrganization(orgData);
        onSaveSuccess(newOrganization); // Pass the updated organization to onSaveSuccess
        setOrganizations([...organizations, newOrganization]);
        
      } catch (error) {
        console.error('Error updating organization:', error);
      } finally {
        onClose();
      }
    }

    setCurrentOrg(null);
    setSsoIntegrations([]);
  };

  const startEditing = (org: ApiOrganization) => {
    console.log(org);
    const ingestionConfig = {
      metadataJson: org.ingestionConfig?.metadataJson || "{}",
      metadataPrompt: org.ingestionConfig?.metadataPrompt || "",
      tensorFields: org.ingestionConfig?.tensorFields || [],
      uploadLimit: org.ingestionConfig?.uploadLimit || 10,
      chunkSize: org.ingestionConfig?.chunkSize || 6000,
      overlap: org.ingestionConfig?.overlap || 1200,
      openAiApiKey: org.ingestionConfig?.openAiApiKey || "",
      pdfProcessing: org.ingestionConfig?.pdfProcessing || "pyPDF2",
      metadataEnabled: org.ingestionConfig?.metadataEnabled || false,
    };

    setCurrentOrg({
      ...org,
      retrievalConfig: {
        retrievedDocLimit: org.retrievalConfig?.retrievedDocLimit || 0,
        retrievedProbLimit: org.retrievalConfig?.retrievedProbLimit || 0,
      },
      llmConfig: {
        temperature: org.llmConfig?.temperature || 0,
        openAiApiKey: org.llmConfig?.openAiApiKey || "",
        systemPrompt: org.llmConfig?.systemPrompt || "",
        functions: org.llmConfig?.functions || "",
        available_tools: org.llmConfig?.available_tools || [],
      },
      brandingSettings: {
        headerDisplayName: org.brandingSettings?.headerDisplayName || ""
      },
      ingestionConfig,
    });

    const selected = (org.llmConfig?.available_tools || []).map((toolName) => ({
      value: toolName,
      label: toolName,
    }));
    setSelectedTools(selected);
    parseMetadataJson(ingestionConfig.metadataJson);
    setIsMetadataEnabled(ingestionConfig.metadataEnabled)
    setSsoIntegrations(org.ssoIntegrations || []);
  };

  const cancelEditing = () => {
    setCurrentOrg(null);
    setSsoIntegrations([]);
  };

  const handleChangeSso = (index: number, field: keyof SsoIntegration, value: string) => {
    const newSsoIntegrations = [...ssoIntegrations];
    newSsoIntegrations[index] = {
      ...newSsoIntegrations[index],
      [field]: value,
    };
    setSsoIntegrations(newSsoIntegrations);
  };

  const addNewSsoIntegration = () => {
    // we only have one SSO integration type. hard code it and don't allow it to be changed.
    setSsoIntegrations([
      ...ssoIntegrations,
      {
        provider: "blackbaud",
        externalId: "",
        secretKey: "",
        issuer: "",
        audience: "",
      },
    ]);
  };

  const generateUuid = (index: number) => {
    const newSsoIntegrations = [...ssoIntegrations];
    newSsoIntegrations[index].externalId = uuidv4();
    setSsoIntegrations(newSsoIntegrations);
  };

  const deleteSsoIntegration = (index: number) => {
    const newSsoIntegrations = [...ssoIntegrations];
    newSsoIntegrations.splice(index, 1);
    setSsoIntegrations(newSsoIntegrations);
  };

  const parseMetadataJson = (json: string) => {
    try {
      const parsed = JSON.parse(json);
      if (parsed && parsed.properties) {
        const options = Object.keys(parsed.properties).map((key) => ({
          value: key,
          label: key.replace(/_/g, " "),
        }));

        if (!options.some((option) => option.value === "source")) {
          options.push({ value: "source", label: "source" });
        }
        setTensorFieldOptions(options);
      } else {
        setTensorFieldOptions([{ value: "source", label: "source" }]);
      }
    } catch (e) {
      setTensorFieldOptions([{ value: "source", label: "source" }]);
      console.error("Failed to parse metadataJson", e);
    }
  };

  const handleJsonChange = (editor: CodeMirror.Editor, data: CodeMirror.EditorChange, value: string) => {
    try {
      const parsed = JSON.parse(value);
      const options = parsed.properties
        ? Object.keys(parsed.properties).map((key) => ({
            value: key,
            label: key.replace(/_/g, " "),
          }))
        : [];

      if (!options.some((option) => option.value === "source")) {
        options.push({ value: "source", label: "source" });
      }

      setTensorFieldOptions(options);
      setMetaError(null); // Clear any previous error
      setCurrentOrg((prevOrg) => ({
        ...prevOrg!,
        ingestionConfig: {
          ...prevOrg!.ingestionConfig,
          metadataJson: value,
        },
      }));
    } catch (e) {
      setMetaError("Invalid JSON");
    }
  };

  const handleBeforeChangeFns = (editor: CodeMirror.Editor, data: CodeMirror.EditorChange, value: string) => {
    setCurrentOrg({
      ...currentOrg!,
      llmConfig: {
        ...currentOrg!.llmConfig,
        functions: value,
      },
    });
  };

  const handleBlurFns = (editor: CodeMirror.Editor, event: React.FocusEvent<HTMLElement>) => {};

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setIsMetadataEnabled(checked);
    setCurrentOrg((prevOrg) => ({
      ...prevOrg!,
      ingestionConfig: {
        ...prevOrg!.ingestionConfig,
        metadataEnabled: checked,
      },
    }));
  };

  const handleToolsChange = (selected: any) => {
    const updatedSelectedTools = Array.isArray(selected)
      ? selected.map((tool) => ({ value: tool.value, label: tool.label }))
      : [];

    setSelectedTools(updatedSelectedTools);

    setCurrentOrg((prevOrg) => ({
      ...prevOrg!,
      llmConfig: {
        ...prevOrg!.llmConfig,
        available_tools: updatedSelectedTools.map((tool) => tool.value),
      },
    }));
  };

  return (
    <>
      <Modal show={onShow} onHide={onClose} size="xl" onClick={handleStopPropagation}>
        <Modal.Header>
          <Modal.Title><h3>{organizationToEdit && organizationToEdit.id == ''? 
                            'Duplicate Organization - ' + currentOrg?.name :
                            organizationToEdit ?
                            'Edit Organization - ' + currentOrg?.name :
                            'New Organization'}</h3></Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={(e) => {e.stopPropagation(); cancelEditing(); onClose();}}></button>
        </Modal.Header>
        <Modal.Body>
          {(
          <div className="mt-4">
            <div className="mb-2">
              <h5>Organization Name</h5>
              <input
                type="text"
                className="form-control"
                value={currentOrg?.name || ''}
                onChange={(e) => setCurrentOrg({ ...currentOrg!, name: e.target.value })}
              />
            </div>

            <Accordion defaultActiveKey="0" alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header onClick={(e) => e.stopPropagation()}><h5>Retrieval Configuration</h5></Accordion.Header>
                <Accordion.Body>
                  <div className={`${styles.card} mt-3`}>
                    <div className="card-body">
                      <h5 className="card-title">Retrieval Configuration</h5>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label>
                              Retrieved Document Limit: {currentOrg?.retrievalConfig?.retrievedDocLimit?.toFixed(1)}
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              min="1"
                              max="50"
                              step="1"
                              value={currentOrg?.retrievalConfig?.retrievedDocLimit || 0}
                              onChange={(e) =>
                                setCurrentOrg({
                                  ...currentOrg!,
                                  retrievalConfig: {
                                    ...currentOrg!.retrievalConfig,
                                    retrievedDocLimit: parseFloat(e.target.value),
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label>
                              Retrieved Probability Limit: {currentOrg?.retrievalConfig?.retrievedProbLimit?.toFixed(1)}
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              min="0.00"
                              max="100"
                              step="0.01"
                              value={currentOrg?.retrievalConfig?.retrievedProbLimit || 0}
                              onChange={(e) =>
                                setCurrentOrg({
                                  ...currentOrg!,
                                  retrievalConfig: {
                                    ...currentOrg!.retrievalConfig,
                                    retrievedProbLimit: parseFloat(e.target.value),
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header onClick={(e) => e.stopPropagation()}><h5>LLM Configuration</h5></Accordion.Header>
              <Accordion.Body>
                <div className={`${styles.card} mt-3`}>
                  <div className="card-body">
                    <h5 className="card-title">LLM Configuration</h5>
                    <div className="mb-2">
                      <label>Temperature (0 to 2): {currentOrg?.llmConfig?.temperature?.toFixed(1)}</label>
                      <input
                        type="number"
                        className="form-control"
                        min="0"
                        max="2"
                        step="0.1"
                        value={currentOrg?.llmConfig?.temperature || 0}
                        onChange={(e) =>
                          setCurrentOrg({
                            ...currentOrg!,
                            llmConfig: {
                              ...currentOrg!.llmConfig,
                              temperature: parseFloat(e.target.value),
                            },
                          })
                        }
                      />
                    </div>

                    <div className="mb-2">
                      <label>Model</label>
                      <Select
                        value={modelOptions.find((option) => option.value === currentOrg?.llmConfig?.model)}
                        onChange={(e) =>
                          setCurrentOrg({
                            ...currentOrg!,
                            llmConfig: {
                              ...currentOrg?.llmConfig,
                              model: e?.value || "",
                            },
                          })
                        }
                        options={modelOptions}
                      />
                    </div>
                    <div className="mb-2">
                      <label>OpenAI API Key</label>
                      <input
                        type="text"
                        className="form-control"
                        value={currentOrg?.llmConfig?.openAiApiKey || ""}
                        onChange={(e) =>
                          setCurrentOrg({
                            ...currentOrg!,
                            llmConfig: {
                              ...currentOrg!.llmConfig,
                              openAiApiKey: e.target.value,
                            },
                          })
                            }
                          />
                      </div>
                        <div className="mb-2">
                          <label>System Prompt</label>
                          <textarea
                            className="form-control"
                            value={currentOrg?.llmConfig?.systemPrompt || ""}
                            rows={10}
                            onChange={(e) =>
                              setCurrentOrg({
                                ...currentOrg!,
                                llmConfig: {
                                  ...currentOrg!.llmConfig,
                                  systemPrompt: e.target.value,
                                },
                              })
                            }
                          />
                        </div>

                        <div className="mb-2">
                          <label>Select Tools</label>
                          <Select
                            isMulti
                            value={selectedTools}
                            onChange={handleToolsChange}
                            options={allTools}
                          />
                        </div>
                        {/* <div className="mb-2">
                      <label>Functions</label>
                      <CodeMirror
                        value={currentOrg?.llmConfig?.functions || ""}
                        options={{
                          theme: "material",
                          lineNumbers: true,
                        }}
                        onBeforeChange={handleBeforeChangeFns}
                        onBlur={handleBlurFns}
                      />
                      {funcError && <div style={{ color: "red" }}>{funcError}</div>}
                    </div> */}
                      </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header onClick={(e) => e.stopPropagation()}><h5>Branding Settings</h5></Accordion.Header>
              <Accordion.Body>
                <div className={`${styles.card} mt-3`}>
                  <div className="card-body">
                    <h5 className="card-title">Branding Settings</h5>
                    <div className="mb-2">
                      <label>Header Display Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={currentOrg?.brandingSettings?.headerDisplayName || ""}
                        onChange={(e) =>
                          setCurrentOrg({
                            ...currentOrg!,
                            brandingSettings: {
                              ...currentOrg!.brandingSettings,
                              headerDisplayName: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          
            
            <Accordion.Item eventKey="3">
              <Accordion.Header onClick={(e) => e.stopPropagation()}><h5>SSO Integration</h5></Accordion.Header>
              <Accordion.Body>
                {ssoIntegrations.map((sso, index) => (
                  <div className={`${styles.card} mt-3`} key={index}>
                    <div className="card-body">
                      <h5 className="card-title">SSO Integration</h5>
                      <button className="btn btn-danger" onClick={() => deleteSsoIntegration(index)}>
                        Delete
                      </button>
                      <div className="mb-3">
                        <label>Provider</label>
                        <input
                          type="text"
                          className="form-control mb-2"
                          value={sso.provider}
                          onChange={(e) => handleChangeSso(index, "provider", e.target.value)}
                          // we only have one SSO integration type. hard code it and don't allow it to be changed.
                          disabled
                        />
                        <label>External ID</label>
                        <div className="input-group mb-2">
                          <input
                            type="text"
                            className="form-control"
                            value={sso.externalId}
                            onChange={(e) => handleChangeSso(index, "externalId", e.target.value)}
                          />
                          <div className="input-group-append">
                            <button className="btn btn-outline-secondary" onClick={() => generateUuid(index)}>
                              Generate UUID
                            </button>
                          </div>
                        </div>
                        <label>Secret Key</label>
                        <input
                          type="text"
                          className="form-control mb-2"
                          value={sso.secretKey}
                          onChange={(e) => handleChangeSso(index, "secretKey", e.target.value)}
                        />
                        <label>Issuer</label>
                        <input
                          type="text"
                          className="form-control mb-2"
                          value={sso.issuer}
                          onChange={(e) => handleChangeSso(index, "issuer", e.target.value)}
                        />
                        <label>Audience</label>
                        <input
                          type="text"
                          className="form-control mb-2"
                          value={sso.audience}
                          onChange={(e) => handleChangeSso(index, "audience", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  ))}
                <div className="mb-2">
                  <button onClick={addNewSsoIntegration} className="btn btn-secondary btn-xs mt-3 ms-auto">
                    Add New SSO Integration
                  </button>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header onClick={(e) => e.stopPropagation()}><h5>Ingestion Configuration</h5></Accordion.Header>
              <Accordion.Body>
                <div className={`${styles.card} mt-3`}>
                  <div className="card-body">
                    <h5 className="card-title">Ingestion Configuration</h5>
                    <div className="mb-2">
                      <label>Upload Limit</label>
                      <input
                        type="number"
                        className="form-control"
                        value={currentOrg?.ingestionConfig?.uploadLimit}
                        onChange={(e) =>
                          setCurrentOrg({
                            ...currentOrg!,
                            ingestionConfig: {
                              ...currentOrg!.ingestionConfig,
                              uploadLimit: parseInt(e.target.value),
                            },
                          })
                        }
                      />
                    </div>
                    <div className="mb-2">
                      <label>Chunk Size</label>
                      <input
                        type="number"
                        className="form-control"
                        value={currentOrg?.ingestionConfig?.chunkSize}
                        onChange={(e) =>
                          setCurrentOrg({
                            ...currentOrg!,
                            ingestionConfig: {
                              ...currentOrg!.ingestionConfig,
                              chunkSize: parseInt(e.target.value),
                            },
                          })
                        }
                      />
                    </div>
                    <div className="mb-2">
                      <label>Overlap</label>
                      <input
                        type="number"
                        className="form-control"
                        value={currentOrg?.ingestionConfig?.overlap}
                        onChange={(e) =>
                          setCurrentOrg({
                            ...currentOrg!,
                            ingestionConfig: {
                              ...currentOrg!.ingestionConfig,
                              overlap: parseInt(e.target.value),
                            },
                          })
                        }
                      />
                    </div>
                    <div className="mb-2">
                      <label>Ingestion OpenAPI Key</label>
                      <input
                        type="text"
                        className="form-control"
                        value={currentOrg?.ingestionConfig?.openAiApiKey}
                        onChange={(e) =>
                          setCurrentOrg({
                            ...currentOrg!,
                            ingestionConfig: {
                              ...currentOrg!.ingestionConfig,
                              openAiApiKey: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                    <div className="mb-2">
                      <label>PDF Processing</label>
                      <select
                        className="form-control mt-2"
                        value={currentOrg?.ingestionConfig?.pdfProcessing || "pyPDF2"}
                        onChange={(e) => setCurrentOrg({ ...currentOrg!, ingestionConfig: {
                          ...currentOrg!.ingestionConfig,
                          pdfProcessing: e.target.value,
                        }})}
                      >
                        <option value="pyPDF2">pyPDF2</option>
                        <option value="Textract">Textract</option>
                      </select>
                    </div>
                    <div>
                      <Form.Check
                        type="checkbox"
                        label="Enable Metadata"
                        checked={isMetadataEnabled}
                        onChange={handleCheckboxChange}
                      />
                      {isMetadataEnabled && (
                        <div>
                          <div className="mb-2">
                            <label>Metadata JSON</label>
                            <CodeMirror
                              value={currentOrg?.ingestionConfig?.metadataJson ?? ""}
                              options={{
                                mode: "application/json",
                                theme: "material",
                                lineNumbers: true,
                                viewportMargin: Infinity,
                              }}
                              onBeforeChange={(editor, data, value) => {
                                setCurrentOrg((prevOrg) => ({
                                  ...prevOrg!,
                                  ingestionConfig: {
                                    ...prevOrg!.ingestionConfig,
                                    metadataJson: value,
                                  },
                                }));
                              }}
                              onChange={handleJsonChange}
                            />
                            {metaError && <div style={{ color: "red" }}>{metaError}</div>}
                          </div>
                          <div className="mb-2">
                            <label>Metadata Prompt</label>
                            <textarea
                              className="form-control"
                              value={currentOrg?.ingestionConfig?.metadataPrompt}
                              onChange={(e) =>
                                setCurrentOrg({
                                  ...currentOrg!,
                                  ingestionConfig: {
                                    ...currentOrg!.ingestionConfig,
                                    metadataPrompt: e.target.value,
                                  },
                                })
                              }
                              rows={5}
                            />
                          </div>
                          <div className="mb-2">
                            <label>TensorFields</label>
                            <Select
                              isMulti
                              value={
                                currentOrg?.ingestionConfig?.tensorFields
                                  ? currentOrg.ingestionConfig.tensorFields.map((field) => ({ value: field, label: field }))
                                  : []
                              }
                              onChange={(selected) => {
                                const updatedTensorFields = selected ? selected.map((item) => item.value) : [];
                                setCurrentOrg({
                                  ...currentOrg!,
                                  ingestionConfig: {
                                    ...currentOrg!.ingestionConfig,
                                    tensorFields: updatedTensorFields,
                                  },
                                });
                              }}
                              options={tensorFieldOptions}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e) => {e.stopPropagation(); cancelEditing(); onClose();}}>
            Cancel
          </Button>
          <Button variant="primary" onClick={(e) => {e.stopPropagation(); createOrUpdateOrganization();}}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrganizationModal;

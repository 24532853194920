import ReactMarkdown from 'react-markdown';
import {Message}  from '../../types/message.type';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/cjs/styles/prism';

interface MarkdownMessageProps {
  message: Message;
  canViewCollections?: boolean;
}

const MarkdownMessage: React.FC<MarkdownMessageProps> = ({ message, canViewCollections }) => {
  return (
    <div className="markdown-message">
      {canViewCollections && message.metadata?.collection_name && message.metadata?.collection_name != "entire-library" && (
        <div className="collection-name-message">
          {"[Collection: "}{message.metadata?.collection_name ?? "entire-library"}{"]"}
        </div>
      )}
      <ReactMarkdown
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <SyntaxHighlighter {...props} style={vscDarkPlus} language={match[1]} PreTag="div">
                {String(children).replace(/\n$/, '')}
              </SyntaxHighlighter>
            ) : (
              <code {...props} className={className}>
                {children}
              </code>
            );
          },
        }}
      >
        {message.content}
      </ReactMarkdown>
    </div>
  );
};

export default MarkdownMessage;

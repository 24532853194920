import axios from "axios";
import authHeader from "./auth-header";
import { API_BASE_URL } from "../constants";
import ApiFile from "../types/file.type";

class FileService {
  private baseUrl = API_BASE_URL + "file";

  async uploadFile(fileData: FormData): Promise<any> {
    try {
      const response = await axios.post(`${this.baseUrl}`, fileData, {
        headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error; // or handle it appropriately
    }
  }
  async getFiles(): Promise<ApiFile[]> {
    try {
      const response = await axios.get(`${this.baseUrl}`, { headers: authHeader() });
      return response.data;
    } catch (error) {
      console.error("Error fetching file:", error);
      throw error; // or handle it appropriately
    }
  }

  async getFilteredFiles(fileIds: string[]): Promise<ApiFile[]> {
    try {
      const response = await axios.post(`${this.baseUrl}/filter`, { fileIds }, { headers: authHeader() });
      return response.data;
    } catch (error) {
      console.error("Error fetching file statuses:", error);
      throw error;
    }
  }

  async deleteFile(file_id: string): Promise<{ message: string }> {
    try {
      const response = await axios.delete(`${this.baseUrl}/${file_id}`, { headers: authHeader() });
      return response.data;
    } catch (error) {
      console.error("Error fetching file:", error);
      throw error; 
    }
  }

  async updateFileCollection(file_id: string, collectionList: string[]): Promise<ApiFile[]> {
    try {
      const response = await axios.put(`${this.baseUrl}/${file_id}/collections`, collectionList, { headers: authHeader() });
      return response.data;
    } catch (error) {
      console.error("Error fetching file:", error);
      throw error; 
    }
  }

  async getSearchDocuments(search_string: string): Promise<[]> {
    try {
      const response = await axios.post(`${this.baseUrl}/search`, { search_string }, { headers: authHeader() });
      return response.data;
    } catch (error) {
      console.error("Error fetching file ids for search string:", error);
      throw error;
    }
  }

  async getFileDownloadURL(file_id: string): Promise<string> {
    try {
      const response = await axios.get(`${this.baseUrl}/${file_id}/download-url`, {
        headers: authHeader(),
      });
      return response.data.presigned_url;
    } catch (error) {
      console.error("Error getting presigned URL:", error);
      throw error; 
    }
  }
}
export default new FileService();
